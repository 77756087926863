enum STATION_SOURCE {
  UNKNOWN = 0,
  IOT = 1,
  GIOS = 2,
  AIRQLAB = 3,
  OPENAQ = 4,
  EKOSLUPEK = 5,
  LORAWAN = 6,
  NASK = 7,
}

export default STATION_SOURCE
