import { Helmet } from 'react-helmet-async'
import clsx from 'clsx'
import type { WithTranslation } from 'react-i18next'
import { withTranslation } from 'react-i18next'

import COLOR_MODE from '../../constants/colorMode'
import { useStore } from '../../state'
import useDarkMode from '../../hooks/useDarkMode'

const colors = {
  [COLOR_MODE.LIGHT]: '#f0f2f5',
  [COLOR_MODE.DARK]: '#121212',
} as const

/**
 * Global color mode toggle + Helmet configuration
 * Note: Should update child component like IonApp, but must have access to state
 * Note: Possible to change <meta name="theme-color" />, see https://web.dev/prefers-color-scheme/#reacting-on-dark-mode-changes
 * Note: Chrome 76+
 *
 * @link https://ionicframework.com/docs/theming/dark-mode#automatically-enable-dark-mode
 */
const ColorModeSwitcher: React.FC<WithTranslation> = ({ t }) => {
  const settings = useStore(state => state.settings)
  const isDarkMode = useDarkMode()

  const { colorMode } = settings

  return (
    <Helmet
      defaultTitle={t('page.default.title')}
      titleTemplate={t('page.default.titleTemplate', { replace: { title: '%s' } })}
    >
      {colorMode === COLOR_MODE.SYSTEM
        ? [
            <meta key="prefers-light" name="theme-color" content={colors.light} media="(prefers-color-scheme: light)" />,
            <meta key="prefers-dark" name="theme-color" content={colors.dark} media="(prefers-color-scheme: dark)" />
          ]
        : <meta key={colorMode} name="theme-color" content={colors[colorMode]} />
      }
      <body className={clsx(isDarkMode && 'dark')} />
    </Helmet>
  )
}

export default withTranslation()(ColorModeSwitcher)
