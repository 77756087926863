import type { TErrorOptions } from './TransferError'
import TransferError from './TransferError'

/**
 * TypeError: Failed to fetch
 */
export default class ConnectError extends TransferError {
  /** @inheritdoc */
  tMessageKey: TransferError['tMessageKey'] = 'error.ConnectError.message'
  /** @inheritdoc */
  tDescriptionKey: TransferError['tDescriptionKey'] = 'error.ConnectError.description'

  /**
   * @inheritdoc
   */
  constructor(
    request: Request,
    options?: TErrorOptions,
  ) {
    super(request, 'No connection', options)

    Object.setPrototypeOf(this, ConnectError.prototype)
  }
}

ConnectError.prototype.name = 'ConnectError'
