export type TSimpleCoords = [
  /** Latitude */
  number,
  /** longitude */
  number,
]

/**
 * Get distance in km between 2 points (Haversine formula)
 * @see https://stackoverflow.com/questions/27928/calculate-distance-between-two-latitude-longitude-points-haversine-formula
 */
export function getDistance(coords1: TSimpleCoords, coords2: TSimpleCoords): number {
  // Radius of the earth in km
  const R = 6371

  const [ lat1, lng1 ] = coords1
  const [ lat2, lng2 ] = coords2

  const dLat = deg2rad(lat2 - lat1)
  const dLng = deg2rad(lng2 - lng1)

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
    Math.sin(dLng / 2) * Math.sin(dLng / 2)

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

  // Distance in km
  return R * c
}

/**
 * Convert degrees to radians
 */
function deg2rad(x: number): number {
  return x * Math.PI / 180
}
