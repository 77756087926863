import { IonLoading } from '@ionic/react'
import type { WithTranslation } from 'react-i18next'
import { withTranslation } from 'react-i18next'

const LoadingOverlay: React.FC<WithTranslation & {
  isOpen: boolean,
}> = ({
  isOpen,
  t,
}) =>
  <IonLoading
    isOpen={isOpen}
    message={t('ui.loading')}
  />

export default withTranslation()(LoadingOverlay)
