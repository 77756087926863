import type { StateStorage } from 'zustand/middleware'
import { Preferences } from '@capacitor/preferences'

/**
 * Persistent state storage factory (Capacitor storage keyval wrapper)
 * Default configured group is 'CapacitorStorage'
 * @link https://github.com/pmndrs/zustand/wiki/Persisting-the-store's-data#how-can-i-use-a-custom-storage-engine
 */
export default function StateStorageFactory (): StateStorage {
  return {
    getItem: key =>
      Preferences
        .get({ key })
        .then(getResult => getResult.value)
    ,

    setItem: (key, value) =>
      Preferences.set({ key, value })
    ,

    removeItem: key =>
      Preferences.remove({ key })
    ,
  }
}
