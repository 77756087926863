import clsx from 'clsx'
import type { WithTranslation } from 'react-i18next'
import { withTranslation } from 'react-i18next'
import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
} from '@ionic/react'

import { useLocation } from 'react-router-dom'

import type { IMenuItems } from '../../navigation'

import LogoType from '../Logotype/Logotype'

import './Menu.css'

const Menu: React.FC<WithTranslation & {
  items: IMenuItems,
  disabled?: boolean,
}> = ({
  items,
  disabled = false,
  t,
}) => {
  const location = useLocation()

  return (
    <IonMenu
      contentId="main"
      disabled={disabled}
      menuId="main-menu"
      side="start"
      type="overlay"
    >
      <IonContent>
        <IonList id="menu-list">
          <IonListHeader>
            <LogoType />
          </IonListHeader>

          {items.map((item, index) => (
            <IonMenuToggle
              key={index}
              autoHide={false}
              menu="main-menu"
            >
              <IonItem
                className={clsx(location.pathname === item.url && 'selected')}
                routerLink={item.url}
                routerDirection="none"
                lines="none"
                detail={false}
              >
                <IonIcon
                  slot="start"
                  ios={item.iosIcon}
                  md={item.mdIcon}
                />
                <IonLabel>
                  {t(`component.Menu.items.${item.title}`)}
                </IonLabel>
              </IonItem>
            </IonMenuToggle>
          ))}
        </IonList>
      </IonContent>
    </IonMenu>
  )
}

export default withTranslation()(Menu)
