import type { DivIconOptions } from 'leaflet'

import type { IStationsReadingItem } from '../../models/Station'
import type { TSensorDataCurrentNorm } from '../../models/Sensor'
import type { TSensorType } from '../../models/sensorType'
import MarkerIcon from './MarkerIcon'

import './StationReadingIcon.css'

/**
 * Station reading icon
 * Just a div with special className and currentNorm property
 */
export default class StationReadingIcon extends MarkerIcon {
  /** Station reading current norm */
  public currentNorm: TSensorDataCurrentNorm

  /**
   * @inheritdoc
   */
  constructor (
    stationReading: IStationsReadingItem,
    protected sensorType: TSensorType,
    protected isCurrent: boolean = false,
    size: number = 16,
    options?: DivIconOptions,
  ) {
    super({
      iconSize: [size, size],
      shadowSize: [size * 4, size * 4],
      ...options,
    })

    this.currentNorm = stationReading.data.current_norm
  }

  /**
   * @inheritdoc
   */
  createIcon (icon?: HTMLElement): HTMLElement {
    if (!icon) {
      icon = document.createElement('span')
      icon.classList.add(
        'leaflet-marker-icon',
        'syn-map-marker-icon',
      )

      this.setIconStyles(icon, this.options.iconSize!)
    }

    this
      .setIconCurrentNorm(icon, this.currentNorm)
      .setIconIsCurrent(icon, this.isCurrent)

    return icon
  }

  /**
   * Hack to use shadow on L.DivIcon
   * Note: Might get laggy in metropolitan areas (~150 markres)
   * Note: Using className instead of dataset as it's easy to use datasets for leaflet elements
   * @inheritdoc
   * @see Icon.createShadow
   */
  createShadow (icon?: HTMLElement): HTMLElement {
    if (!icon) {
      icon = document.createElement('span')
      icon.classList.add(
        'leaflet-marker-shadow',
        'syn-map-marker-cloud',
      )

      this.setIconStyles(icon, this.options.shadowSize!)
    }

    this.setIconCurrentNorm(icon, this.currentNorm)

    return icon
  }

  /**
   * Set icon isCurrent className
   */
  protected setIconIsCurrent(icon: HTMLElement, isCurrent: boolean): this {
    icon.classList.toggle('syn-map-marker-icon--current', isCurrent)

    return this
  }
}

/**
 * Marker icon factory function
 */
export function stationReadingIcon(...params: ConstructorParameters<typeof StationReadingIcon>): StationReadingIcon {
  return new StationReadingIcon(...params)
}
