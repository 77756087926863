/**
 * Available color modes/ schemes
 */
enum COLOR_MODE {
  LIGHT  = 'light',
  DARK   = 'dark',
  SYSTEM = 'system',
}

export default COLOR_MODE

/**
 * Export types
 */
export type TColorMode = typeof COLOR_MODE[keyof typeof COLOR_MODE]
