import useSWR from 'swr'
import type { SWRConfiguration } from 'swr'

import type { TUseResourceReturnType } from '../models/useApi'
import type { INorms } from '../models/Norms'
import SENSOR_TYPE from '../constants/sensorType'
import { DAYS } from '../constants/time'
import TransferError from '../utils/Error/TransferError'

const apiRefreshInterval = 1 * DAYS

const fallbackData: INorms = [
  { slug: SENSOR_TYPE.PM2_5,            unit: 'µg/m³', norm: { threshold:  25, grade_a: { gte:   0, lt:  13 }, grade_b: { gte: 13, lt:  35 }, grade_c: { gte:  35, lt:  55 }, grade_d: { gte:  55, lt:  75 }, grade_e: { gte:  75, lt: 110 }, grade_f: { gte: 110 }} },
  { slug: SENSOR_TYPE.PM10,             unit: 'µg/m³', norm: { threshold:  50, grade_a: { gte:   0, lt:  20 }, grade_b: { gte: 20, lt:  50 }, grade_c: { gte:  50, lt:  80 }, grade_d: { gte:  80, lt: 110 }, grade_e: { gte: 110, lt: 150 }, grade_f: { gte: 150 }} },
  { slug: SENSOR_TYPE.CO,               unit: "mg/m³", norm: { threshold:  50, grade_a: { gte:   0, lt:  25 }, grade_b: { gte: 25, lt:  65 }, grade_c: { gte:  65, lt: 105 }, grade_d: { gte: 105, lt: 145 }, grade_e: { gte: 145, lt: 205 }, grade_f: { gte: 205 }} },
  { slug: SENSOR_TYPE.NO2,              unit: 'µg/m³', norm: { threshold:  45, grade_a: { gte:   0, lt:  40 }, grade_b: { gte: 40, lt: 100 }, grade_c: { gte: 100, lt: 150 }, grade_d: { gte: 150, lt: 200 }, grade_e: { gte: 200, lt: 400 }, grade_f: { gte: 400 }} },
  { slug: SENSOR_TYPE.SO2,              unit: 'µg/m³', norm: { threshold: 100, grade_a: { gte:   0, lt:  50 }, grade_b: { gte: 50, lt: 100 }, grade_c: { gte: 100, lt: 200 }, grade_d: { gte: 200, lt: 350 }, grade_e: { gte: 350, lt: 500 }, grade_f: { gte: 500 }} },
  { slug: SENSOR_TYPE.O3,               unit: 'µg/m³', norm: { threshold:  50, grade_a: { gte:   0, lt:  30 }, grade_b: { gte: 30, lt:  70 }, grade_c: { gte:  70, lt: 120 }, grade_d: { gte: 120, lt: 160 }, grade_e: { gte: 160, lt: 240 }, grade_f: { gte: 240 }} },
  { slug: SENSOR_TYPE.C6H6,             unit: 'µg/m³', norm: { threshold:   1, grade_a: { gte:   0, lt:   5 }, grade_b: { gte:  5, lt:  10 }, grade_c: { gte:  10, lt:  15 }, grade_d: { gte:  15, lt:  20 }, grade_e: { gte:  20, lt:  50 }, grade_f: { gte:  50 }} },
  // Part of API response, but not useful as not part of sensors visible to user
  /*
  { slug: SENSOR_TYPE.CAQI,             unit: 'CAQI',  norm: { threshold:  30, grade_a: { gte:   0, lt:  25 }, grade_b: { gte: 25, lt:  50 }, grade_c: { gte:  50, lt:  75 }, grade_d: { gte:  75, lt: 100 }, grade_e: { gte: 100 }, grade_f: []} },
  { slug: SENSOR_TYPE.NOISE,            unit: 'db',    norm: { threshold:  65, grade_a: { gte:   0, lt:  40 }, grade_b: { gte: 40, lt:  55 }, grade_c: { gte:  55, lt:  60 }, grade_d: { gte:  60, lt:  65 }, grade_e: { gte:  65, lt:  80 }, grade_f: { gte:  80 }} },
  { slug: SENSOR_TYPE.SOIL_VWC,         unit: '%',     norm: { threshold: 100, grade_a: { gte:   0, lt:  10 }, grade_b: { gte: 10, lt:  20 }, grade_c: { gte:  20, lt:  30 }, grade_d: { gte:  30, lt:  40 }, grade_e: { gte:  40, lt:  50 }, grade_f: { gte:  50 }} },
  { slug: SENSOR_TYPE.SOIL_TEMPERATURE, unit: 'ºC',    norm: { threshold: 100, grade_a: { gte: -40, lt: -20 }, grade_b: { gte: -2, lt:   0 }, grade_c: { gte:   0, lt:  20 }, grade_d: { gte:  20, lt:  40 }, grade_e: { gte:  40, lt:  55 }, grade_f: { gte:  55 }} },
  { slug: SENSOR_TYPE.SOIL_AP,          unit: '',      norm: { threshold: 100, grade_a: { gte:   0, lt:  35 }, grade_b: { gte: 35, lt:  70 }, grade_c: { gte:  70, lt: 140 }, grade_d: { gte: 140, lt: 160 }, grade_e: { gte: 160, lt: 180 }, grade_f: { gte: 180 }} },
  { slug: SENSOR_TYPE.CH2O,             unit: 'µg/m³', norm: { threshold:  50, grade_a: { gte:   0, lt:   2 }, grade_b: { gte:  2, lt:   4 }, grade_c: { gte:   4, lt:   8 }, grade_d: { gte:   8, lt:  20 }, grade_e: { gte:  20, lt:  40 }, grade_f: { gte:  40 }} },
  */
]

/**
 * Fetch norms
 */
export default function useNorms (
  shouldFetch: boolean = true,
  config?: SWRConfiguration<INorms, TransferError>
): TUseResourceReturnType<INorms, INorms> {
  const endpoint = 'public/norms'

  const { data, error, isValidating, mutate } = useSWR<INorms, TransferError>(shouldFetch ? endpoint : null, {
    refreshInterval: apiRefreshInterval,
    dedupingInterval: apiRefreshInterval,
    fallbackData,
    ...config,
  })

  return {
    data: data as INorms,
    error,
    endpoint,
    isLoading: data === fallbackData && !error,
    isValidating,
    isFallbackData: data === fallbackData,
    mutate,
  }
}
