import type { INormGradeKey } from '../../models/Norms'
import type { IStationSensorWithNorm } from '../../models/Sensor'

/**
 * Get grade constant from station sensors' data
 */
export function getGradeKey(stationSensor: IStationSensorWithNorm): INormGradeKey | null {
  if (!stationSensor.data.length) {
    return null
  }

  const currentNorm = stationSensor.data[0].current_norm

  // Convert current_norm format to grade name
  return currentNorm
    ? currentNorm.replace('-', '_') as INormGradeKey
    : null
}
