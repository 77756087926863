// Promise.prototype.finally() for Chrome 60..63
import 'core-js/stable/promise/finally'

import { isPlatform } from '@ionic/react'
import ResizeObserverPolyfill from 'resize-observer-polyfill'

/**
 * ResizeObserver for react-virtuoso and Safari < 13.1
 * @link https://github.com/petyosi/react-virtuoso/blob/v2.9.0/README.md#browser-support
 */
if (window && !window.ResizeObserver) {
  window.ResizeObserver = ResizeObserverPolyfill
}

/**
 * On Apple mobile devices add the proprietary app icon and splashscreen markup.
 * Note: Cannot test on Windows as script checks if navigator.vendor has Apple
 * @link https://web.dev/learn/pwa/enhancements/#splash-screens
 * @link https://github.com/GoogleChromeLabs/pwacompat
 */
if (
  // iOS
  isPlatform('ios') &&
  // Not pwa installed
  !isPlatform('pwa') &&
  // Not native
  !isPlatform('hybrid')
) {
  window.addEventListener('load', () => {
    const scriptPWACompat = document.createElement('script')

    scriptPWACompat.src = 'https://unpkg.com/pwacompat'
    scriptPWACompat.setAttribute('crossorigin', 'anonymous')

    document.head.appendChild(scriptPWACompat)
  })
}
