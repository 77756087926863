import { useEffect, useRef } from 'react'
import type {
  TileEvent,
  TileErrorEvent,
  TileEventHandlerFn,
  TileErrorEventHandlerFn,
} from 'leaflet'

import { SECONDS } from '../constants/time'

/**
 * Tile load event handlers
 * @link https://github.com/Leaflet/Leaflet/issues/6582#issuecomment-491549149
 */
const useTileLoadEvents = (isVisible: boolean = true): {
  handleTileLoad: TileEventHandlerFn,
  handleTileUnload: TileEventHandlerFn,
  handleTileError: TileErrorEventHandlerFn,
} => {
  const failuresCountRef = useRef(0)
  const retryTimeoutsRef = useRef(new Map<HTMLImageElement, number | undefined>())

  /**
   * Tile load error
   */
  function handleTileError ({ tile }: TileErrorEvent): void {
    retryTimeoutsRef.current.set(tile, window.setTimeout(
      () => retryTileLoad(tile),
      5 * SECONDS + Math.pow(++failuresCountRef.current, 3) * 10,
    ))
  }

  /**
   * Tile load
   */
  function handleTileLoad ({ tile }: TileEvent): void {
    removeTileRetries(tile)

    failuresCountRef.current = 0

    // Restart all
    retryTimeoutsRef.current.forEach((timeoutId, tile) => {
      window.clearTimeout(timeoutId)
      retryTileLoad(tile)
    })
  }

  /**
   * Tile unload
   */
  function handleTileUnload ({ tile }: TileEvent): void {
    removeTileRetries(tile)
  }

  /**
   * Cleanup timeouts when hidden, restart when visible
   */
  useEffect(() => {
    if (isVisible) {
      return
    }

    // Stop timeouts
    retryTimeoutsRef.current.forEach((timeoutId, tile) => {
      window.clearTimeout(timeoutId)
      retryTimeoutsRef.current.set(tile, undefined)
    })

    // Retry
    return () => {
      failuresCountRef.current = 0
      retryTimeoutsRef.current.forEach((timeoutId, tile) => retryTileLoad(tile)) // eslint-disable-line react-hooks/exhaustive-deps
    }
  }, [isVisible])

  return {
    handleTileLoad,
    handleTileUnload,
    handleTileError,
  }

  /**
   * Stop reloading tile
   */
  function removeTileRetries(tile: HTMLImageElement): void {
    if (retryTimeoutsRef.current.has(tile)) {
      window.clearTimeout(retryTimeoutsRef.current.get(tile))
      retryTimeoutsRef.current.delete(tile)
    }
  }
}

export default useTileLoadEvents

/**
 * Reload tile
 * Error triggets handleTileError handler
 */
function retryTileLoad(tile: HTMLImageElement): void {
  const url = new URL(tile.src)
  url.searchParams.set('cache-buster', Date.now().toString())
  tile.src = url.toString()
}
