import { ConnectError, ApiError } from './Error'

declare type ApiFetcher = (path: string, requestInit?: RequestInit) => Promise<any>

export function createFetcher(baseUrl: string): ApiFetcher {
  /**
   * @throws {TransferError}
   * @param {string} path
   * @param {string | ArgumentsTuple | Record<any, any>} restKeyArguments
   * @see {@link https://swr.vercel.app/docs/arguments}
   *
   * Detect msw response
   * response.headers.get('x-powered-by') === 'msw'
   */
  return async (path: string, ...restKeyArguments: any[]) => {
    const url = `${baseUrl}/${path}`
    const request = new Request(url, undefined)

    let response: Response

    try {
      response = await fetch(request)
    } catch (typeError) {
      // Problem: First thrown error is not an instance of ConnectError in swr
      throw new ConnectError(request, { cause: typeError as TypeError })
    }

    /**
     * Handle API errors
     * @see https://swr.vercel.app/docs/error-handling
     */
    if (!response.ok) {
      throw new ApiError(request, response)
    }

    return response.json()
  }
}
