/**
 * Supported sensors types with translation available, order matters
 *
 * A) Part of sensors API response (hardcoded sensor types with norms), also shown in Panel
 *   @see https://api.syngeos.pl/api/public/sensors
 * T) Sensor types table
 * N) Norms table
 *   @see https://api.syngeos.pl/api/public/norms
 */
enum SENSOR_TYPE {
  PM2_5            = 'pm2_5',            // A T N
  PM10             = 'pm10',             // A T N
  SO2              = 'so2',              // A T N
  NO2              = 'no2',              // A T N
  CO               = 'co',               // - T N
  O3               = 'o3',               // A T N
  C6H6             = 'c6h6',             // A T N
  PM1              = 'pm1',              // - T -
  TEMPERATURE      = 'temperature',      // - T -
  AIR_PRESSURE     = 'air_pressure',     // - T -
  HUMIDITY         = 'humidity',         // - T -
  // CAQI             = 'caqi',             // A T N
  CH2O             = 'ch2o',             // A T N
  NOISE            = 'noise',            // A T N
  /** Volumetric water content of the soil (humidity) */
  // SOIL_VWC         = 'soil_vwc',         // - T N
  // SOIL_TEMPERATURE = 'soil_temperature', // - T N
  /** Electrical conductivity of the soil */
  // SOIL_AP          = 'soil_ap',          // - T N
  // CO2              = 'co2',              // - - N
  // UV               = 'uv',               // - - N
  // LIGHT_INTENSITY  = 'light_intensity',  // A T N
}

export default SENSOR_TYPE

/**
 * List of sensor types visible to user with available translations
 */
export const sensorTypes = Object.values(SENSOR_TYPE)

/**
 * Sensor type with info
 */
export const guideSensorTypes = [
  SENSOR_TYPE.PM2_5,
  SENSOR_TYPE.PM10,
  SENSOR_TYPE.SO2,
  SENSOR_TYPE.NO2,
  SENSOR_TYPE.CO,
  SENSOR_TYPE.O3,
  SENSOR_TYPE.C6H6,
  SENSOR_TYPE.CH2O,
  SENSOR_TYPE.NOISE,
] as const

/**
 * List of weather sensor types
 */
export const weatherSensorTypes = [
  SENSOR_TYPE.TEMPERATURE,
  SENSOR_TYPE.AIR_PRESSURE,
  SENSOR_TYPE.HUMIDITY,
] as const
