import { Capacitor } from '@capacitor/core'
import { register } from '../../serviceWorkerRegistration'

import { useEffect } from 'react'
import { useIonToast } from '@ionic/react'
import type { WithTranslation } from 'react-i18next'
import { withTranslation } from 'react-i18next'

import { MINUTES, SECONDS } from '../../constants/time'

import { isStableAppRelease } from '../../utils/version'

/**
 * Service worker handler props
 */
interface ServiceWorkerHandlerProps extends WithTranslation {
  checkInterval?: number,
}

/**
 * Service worker handler
 * Use as soon as possible in component tree, but within ion-app component as serviceWorkerRegistration triggers after window.onload event
 * @link https://cra.link/PWA
 * Note: Only one SW may be active, so CRA and MSW replaces each other depending on env.
 * Note: Do not unregister service worker in dev env as it would break msw
 * TODO: Adapt behaviour when inside Trusted Web Activity (detection: `document.referrer.includes('android-app://[appId]')`)
 *       See 'com.google.android.apps.mapslite'
 * @link https://web.dev/customize-install/#track-how-the-pwa-was-launched
 */
const ServiceWorkerHandler: React.FC<ServiceWorkerHandlerProps> = ({
  t,
  children,
  checkInterval = 15 * MINUTES
}) => {
  const [ present ] = useIonToast()

  // Register service worker when platform isn't native
  useEffect(() => {
    if (
      Capacitor.isNativePlatform() ||
      process.env.NODE_ENV === 'development' ||
      process.env.NODE_ENV === 'test'
    ) {
      return
    }

    if (document.readyState === 'complete')
      console.log('Warning: SW registration will not trigger')

    // Register service worker
    register({
      // App is updated in background
      // See: https://web.dev/manifest-updates/#cr-android-trigger
      onUpdate (registration) {
        // Skip when switching service workers (dev env: msw -> prod: app sw) on same origin
        if (registration.active!.scriptURL !== registration.waiting!.scriptURL) {
          return registration.waiting!.postMessage({ type: 'SKIP_WAITING' })
        }

        present({
          message: t('component.ServiceWorkerHandler.updated'),
          duration: isStableAppRelease ? 15 * SECONDS : 0,
          buttons: [{
            text: t('ui.button.restart'),
            handler: () => {
              // Wait until new sw is activated to prevent new sw on old frontend
              // Note: TypeScript 4.6 doesn't have dedicated statechange event
              registration.waiting!.addEventListener('statechange', ({ target }) =>
                (target as ServiceWorker).state === 'activated' && window.location.reload()
              )

              // Skip waiting phase (waiting for navigation) and activate immediately
              registration.waiting!.postMessage({ type: 'SKIP_WAITING' })
            },
          }],
        })
      }
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // Schedule update checks for pre-releases
  useEffect(() => {
    if (isStableAppRelease || !navigator.serviceWorker) {
      return
    }

    const updateTimeoutId: number = window.setInterval(() =>
      document.visibilityState === 'visible' && navigator.serviceWorker.getRegistration()
        // Ignore update TypeError: Failed to update a ServiceWorker for scope ('https://foobar.com/') with script ('https://foobar.com/service-worker.js'): A bad HTTP response code (404) was received when fetching the script.
        .then(registration => registration?.update().catch(() => {})),
      checkInterval
    )

    return () => window.clearInterval(updateTimeoutId)
  }, [checkInterval])

  return children as React.ReactElement
}

export default withTranslation()(ServiceWorkerHandler)

/**
 * Service worker handler Higher-Order Component
 */
export function withServiceWorkerHandler<P>(
  Component: React.ComponentType<P>,
  serviceWorkerHandlerProps: ServiceWorkerHandlerProps,
): React.ComponentType<P> {
  const componentDisplayName = Component.displayName || Component.name || 'unknown'

  const Wrapped: React.ComponentType<P> = props =>
    <ServiceWorkerHandler {...serviceWorkerHandlerProps}>
      <Component {...props } />
    </ServiceWorkerHandler>

  Wrapped.displayName = `withServiceWorkerHandler(${componentDisplayName})`

  return Wrapped
}
