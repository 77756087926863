import { Trans } from 'react-i18next'

import type { TSensorType } from '../../models/sensorType'

/**
 * Translated sensor type
 */
const SensorType: React.FC<{
  sensorType: TSensorType,
}> = ({
  sensorType
}) =>
  <Trans i18nKey={`sensorType.${sensorType}.label`} />

export default SensorType
