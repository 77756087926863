import type { ReactNode } from 'react'
import { Helmet } from 'react-helmet-async'
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonContent,
} from '@ionic/react'

import { routes } from '../../navigation'
import { isStableAppRelease } from '../../utils/version'

import LogoType from '../Logotype/Logotype'

import './Page.css'

/**
 * Generic ionic page with header and title
 */
const Page: React.FC<{
  /** ID */
  id: string,
  /** Title */
  title?: string,
  /** Dashboard page */
  dashboard?: boolean
  /** Button for slot=primary (far right), defaults to back button */
  toolbarPrimaryButton?: ReactNode,
  /** Button for slot=start */
  toolbarStartButton?: ReactNode,
  /** Seconddary toolbar element */
  secondaryToolbar?: ReactNode,
  /** Ion-Content properties {@link https://ionicframework.com/docs/api/content#properties} */
  contentProps?: object,
}> = ({
  id,
  title,
  dashboard = false,
  toolbarPrimaryButton = undefined,
  toolbarStartButton = <IonBackButton defaultHref={routes.dashboard} />,
  secondaryToolbar = undefined,
  contentProps = { fullscreen: true },
  children,
}) =>
  <IonPage id={id} className="syn-page">
    <Helmet title={title} />
    <IonHeader className="ion-no-border" translucent>
      <IonToolbar>
        {/** Logo on dashboard */}
        {dashboard &&
          <IonTitle className="syn-title">
            <LogoType
              className="syn-logotype--toolbar"
              serviceMenu={!isStableAppRelease}
            />
          </IonTitle>
        }

        {/** Button in start slot */}
        {toolbarStartButton &&
          <IonButtons slot="start">
            {toolbarStartButton}
          </IonButtons>
        }

        {/** Button in primary slot */}
        {toolbarPrimaryButton &&
          <IonButtons slot="primary">
            {toolbarPrimaryButton}
          </IonButtons>
        }
      </IonToolbar>
      {/** Secondary toolbar component */}
      {secondaryToolbar}
    </IonHeader>

    <IonContent {...contentProps}>
      {children}
    </IonContent>
  </IonPage>

export default Page
