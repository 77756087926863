import type {
  Map,
  Marker,
  MarkerCluster,
} from 'leaflet'
import { LatLngBounds } from 'leaflet'

import type { TSensorDataCurrentNorm } from '../../models/Sensor'
import MarkerIcon from './MarkerIcon'
import StationReadingIcon from './StationReadingIcon'

import './MarkerClusterIcon.css'

/**
 * Marker cluster icon
 * Alternative: Use coverage
 * @link https://stackoverflow.com/questions/42490937/show-cluster-bounds-always
 * @link https://github.com/Leaflet/Leaflet.markercluster/issues/763
 */
class MarkerClustererIcon extends MarkerIcon {
  /**
   * @inheritdoc
   */
  public constructor (protected markerCluster: MarkerCluster) {
    const size = getMapRelativeSize(markerCluster)
    // const size = getAbsoluteSize(markerCluster)

    const iconSize = Math.max(size / 3, 25)
    const shadowSize = Math.max(size, 60)

    super({
      iconSize: [iconSize, iconSize],
      shadowSize: [shadowSize, shadowSize],
    })
  }

  /**
   * @inheritdoc
   */
  createIcon (oldIcon?: HTMLElement): HTMLElement {
    // Never
    if (oldIcon) {
      return oldIcon
    }

    const childCount = this.markerCluster.getChildCount()
    const childCountDecimals = Math.min(childCount.toFixed(0).length, 3)

    const icon = document.createElement('span')

    icon.classList.add(
      'leaflet-marker-icon',
      'syn-map-marker-cluster-icon',
      `syn-map-marker-cluster-icon--count-${childCountDecimals}d`
    )

    icon.innerText = childCount.toString()

    this.setIconStyles(icon, this.options.iconSize!)

    return icon
  }

  /**
   * @inheritdoc
   */
  createShadow (oldIcon?: HTMLElement): HTMLElement {
    // Never
    if (oldIcon) {
      return oldIcon
    }

    const avgCurrentNorm = getAvgCurrentNorm(this.markerCluster.getAllChildMarkers())

    const icon = document.createElement('span')

    icon.classList.add(
      'leaflet-marker-shadow',
      'syn-map-marker-cloud',
    )

    this
      .setIconCurrentNorm(icon, avgCurrentNorm)
      .setIconStyles(icon, this.options.shadowSize!)

    return icon
  }
}

/**
 * Marker cluster factory iconCreateFunction
 */
export function markerClusterIcon(markerCluster: MarkerCluster): MarkerClustererIcon {
  return new MarkerClustererIcon(markerCluster)
}

/**
 * Get average for current norms.
 * Should work with ranges (and threshold)
 * Assumes all markers have icon of StationReadingIcon
 */
function getAvgCurrentNorm(markers: Marker[]): TSensorDataCurrentNorm {
  const totalCurrentNormValue = markers.reduce<number>((prevValue, marker) => {
    const icon = marker.getIcon()

    return icon instanceof StationReadingIcon
      ? prevValue + getCurrentNormValue(icon.currentNorm)
      : prevValue
  }, 0)

  return getCurrentNorm(Math.round(totalCurrentNormValue / markers.length))
}

/**
 * Get icon size by calculating distance between 2 outermost coordinates
 */
function getMapRelativeSize(markerCluster: MarkerCluster): number {
  const convexHull = markerCluster.getConvexHull()
  const bounds = new LatLngBounds(convexHull.map(latLng => [latLng.lat, latLng.lng]))

  // @ts-ignore
  const map: Map = markerCluster._map

  // Pixel coords
  const nwPoint = map.latLngToLayerPoint(bounds.getNorthWest())
  const sePoint = map.latLngToLayerPoint(bounds.getSouthEast())

  return nwPoint.distanceTo(sePoint)
}

/**
 * Get icon size by calculating child count
 * Doesn't update on zoom, which is fine
 * To get zoom acces markerCluster._zoom
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function getAbsoluteSize(markerCluster: MarkerCluster): number {
  const childCount = markerCluster.getChildCount()

  return 32 * Math.sqrt(Math.sqrt(childCount))
}

/**
 * Get current norm value
 * Notice: This is not an reading value which is not exposed by API
 */
function getCurrentNormValue(currentNorm: TSensorDataCurrentNorm): number {
  switch (currentNorm) {
    case 'grade-a': return 0
    case 'grade-b': return 1
    case 'grade-c': return 2
    case 'grade-d': return 3
    case 'grade-e': return 4
    case 'grade-f': return 5
  }
}

/**
 * Get enum from value
 */
function getCurrentNorm(currentNormValue: number): TSensorDataCurrentNorm {
  switch (currentNormValue) {
    case 0: return 'grade-a'
    case 1: return 'grade-b'
    case 2: return 'grade-c'
    case 3: return 'grade-d'
    case 4: return 'grade-e'
    case 5: return 'grade-f'
    default: return 'unknown' as never
  }
}
