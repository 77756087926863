import type { IStationSensor } from '../../models/Sensor'
import type { TSensorType } from '../../models/sensorType'
import { sensorTypes } from '../../constants/sensorType'

/**
 * Make collection unique per sensor type
 * It's possible for station to have multiple sensors of same type (5664: temperature, humidity) however let's not confuse users and react keys.
 * Assume sensor on top of a list are older ones to be dropped
 * Examples:
 * https://api.syngeos.pl/api/public/data/device/5664
 * https://api.syngeos.pl/api/public/data/device/5664/historical
 */
export function selectUnique<T = string>(stationSensos: IStationSensor<T>[]): IStationSensor<T>[] {
  // Create map of unique entries
  const unique = new Map(stationSensos.map(stationSensor =>
    [stationSensor.name, stationSensor]
  ))

  // Convert values to array
  return Array.from(unique.values())
}

/**
 * Filter available + sort by hardcoded order (may switch to settings order)
 */
export function selectStationSensors(stationSensors: IStationSensor<string>[]): IStationSensor[] {
  return stationSensors
    // Filter visible
    .filter((stationSensor): stationSensor is IStationSensor =>
      isAvailableSensorType(stationSensor.name)
    )
    // Sort by harcoded order (may switch to settings order)
    .sort((stationSensorA, stationSensorB) =>
      sensorTypes.indexOf(stationSensorA.name) -
      sensorTypes.indexOf(stationSensorB.name)
    )
}

/**
 * Filter selected
 */
export function selectSelectedStationSensors(staionSensors: IStationSensor[], selectedSensors: TSensorType[]): IStationSensor[] {
  return staionSensors.filter(stationSensor =>
    selectedSensors.includes(stationSensor.name))
}

/**
 * Custom guard
 */
function isAvailableSensorType(sensorType: TSensorType | any): sensorType is TSensorType {
  return sensorTypes.includes(sensorType)
}
