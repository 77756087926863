import type { IStationSensor } from '../../models/Sensor'
import SENSOR_TYPE from '../../constants/sensorType'

import { ReactComponent as BarometerIcon } from './images/wi-barometer--simple.svg'
import { ReactComponent as RaindropIcon } from './images/wi-raindrop--outline.svg'
import { ReactComponent as DayCloudyIcon } from './images/wi-day-cloudy.svg'
import { ReactComponent as UmbrellaIcon } from './images/wi-umbrella.svg'

import './WeatherItem.css'

/**
 * Assign icon to sensor type
 * Icons from {@link https://github.com/erikflowers/weather-icons}, but simplified. Other resources:
 * @link https://www.alessioatzeni.com/meteocons/
 * @link https://icons8.com/icons/set/weather
 * @link https://fontawesome.com/icons/categories/weather
 */
const iconsMap = new Map<SENSOR_TYPE, React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>>([
  [SENSOR_TYPE.TEMPERATURE,  DayCloudyIcon],
  [SENSOR_TYPE.AIR_PRESSURE, BarometerIcon],
  [SENSOR_TYPE.HUMIDITY,     RaindropIcon],
  // [SENSOR_TYPE.UV,           UmbrellaIcon],
  [SENSOR_TYPE.O3,           UmbrellaIcon]
])

/**
 * Weather item
 */
const WeatherItem: React.FC<{
  stationSensor: IStationSensor,
}> = ({
  stationSensor,
}) => {
  // Resolve icon
  const Icon = iconsMap.get(stationSensor.name)

  return (
    <div className="syn-weather-item">
      {/** Icon */}
      <div className="syn-weather-item__icon">
        {Icon && <Icon className="syn-weather-item__icon-svg" />}
      </div>
      {/** Value */}
      <div className="syn-weather-item__value">
        {stationSensor.data.length
          ? formatValue(stationSensor.data[0].value)
          : '—'
        }
      </div>
      {/** Unit */}
      <div className="syn-weather-item__unit">
        {stationSensor.unit}
      </div>
    </div>
  )
}

/**
 * Format value by using fraction digits only when needed
 */
function formatValue(value: number, fractionDigits: number = 2): string {
  const [ full, fraction ] = value
    .toString()
    .split('.')

  return fraction
    ? value.toFixed(Math.min(fraction.length, fractionDigits, 0))
    : full
}

export default WeatherItem
