import type { IStorageHandler } from '../packages/swr-idb-cache/types'
import { timestampStorageHandler } from '../packages/swr-idb-cache'

import { DAYS } from '../constants/time'

type TData = any
type TStoreObject = {
  value: TData,
  ts: number,
}

/**
 * Timestamp storage handler with garbage collector
 * Removes stations data older than 7 days which are not favorite
 */
export default class StorageHandler implements IStorageHandler<TData, TStoreObject> {
  protected stationKeyRegExp = RegExp('^public/data/device/(\\d+)')
  protected stationMaxAge = 7 * DAYS

  constructor (protected favStations: number[]) {}

  /**
   * @inheritdoc
   */
  upgradeObjectStore = timestampStorageHandler.upgradeObjectStore

  /**
   * @inheritdoc
   */
  replace (key: string, value: TData): TStoreObject | undefined {
    return this.shouldPersist(key)
      ? timestampStorageHandler.replace(key, value)
      : undefined
  }

  /**
   * @inheritdoc
   */
  revive (key: string, storeObject: TStoreObject): TData | undefined {
    return this.shouldPersist(key) && storeObject.ts > Date.now() - this.stationMaxAge
      ? timestampStorageHandler.revive(key, storeObject)
      : undefined
  }

  /**
   * Should persist cache entry
   */
  protected shouldPersist (key: string): boolean {
    const match = this.stationKeyRegExp.exec(key)

    // Not station path or fav station
    return !match || this.favStations.includes(Number(match[1]))
  }
}
