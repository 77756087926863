/**
 * Ionic Breakpoints
 * @see https://ionicframework.com/docs/layout/css-utilities#ionic-breakpoints
 */

export type TMediaBreakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl'

/**
 * Media breakpoint names
 */
enum MediaBreakpoint {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
}

export default MediaBreakpoint

/**
 * Media breakpoint widths
 */
export const mediaBreakpointQueries = new Map<TMediaBreakpoint, string>([
  [MediaBreakpoint.xs, '(min-width: 0px)'],
  [MediaBreakpoint.sm, '(min-width: 576px)'],
  [MediaBreakpoint.md, '(min-width: 768px)'],
  [MediaBreakpoint.lg, '(min-width: 992px)'],
  [MediaBreakpoint.xl, '(min-width: 1200px)'],
])

export function isMediaBreakpoint(name: string): name is MediaBreakpoint {
  return name in MediaBreakpoint
}
