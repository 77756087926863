import type { IStationSensorWithNorm } from '../../models/Sensor'

import { getGradeKey } from '../../utils/format/grade'

import { ReactComponent as ColorWheel6Grade } from './images/color-wheel--6-grade.svg'
import { ReactComponent as ColorWheelMono } from './images/color-wheel--mono.svg'

import './ColorWheel.css'

type TColorWheelType = '6-grade' | 'mono'

/**
 * Color wheel
 * requires color-wheel-defs injected in document
 */
const ColorWheel: React.FC<{
  type: TColorWheelType,
  stationSensor?: IStationSensorWithNorm,
}> = ({
  type,
  stationSensor = undefined,
}) => {
  const gradeKey = stationSensor
    ? getGradeKey(stationSensor) ?? 'unknown'
    : 'skeleton'

  return type === '6-grade'
    ?
      <ColorWheel6Grade
        className={`syn-color-wheel syn-color-wheel--6-grade syn-color-wheel--grade-${gradeKey}`}
      />
    : <ColorWheelMono
        className={`syn-color-wheel syn-color-wheel--mono syn-color-wheel--grade-${gradeKey}`}
      />

}

export default ColorWheel
