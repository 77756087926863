
/**
 * Safe check to allow Ionic execute on devices with WebViews v60 on Android version with lower WebView versions (ie. Android 6 with stock WebView v44)
 * Requirements:
 * - Capacitor: Chromium 60+ (@link https://capacitorjs.com/docs/android#android-support)
 * - Ionic v5: Chromium 49+ (@link https://caniuse.com/css-variables)
 * - Ionic v6: Chromium 60+ (@link https://ionicframework.com/docs/reference/browser-support)
 *
 * Example of Android 6 + WebView 98 user agent
 * - navigator.userAgent: "Mozilla/5.0 (Linux; Android 6.0.1; MotoG3 Build/MPIS24.107-55-2-17; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/98.0.4758.101 Mobile Safari/537.36 Cordova"
 * - navigator.userAgentData: undefined
 *
 * Note: navigator.userAgent is deprecated on Chrome 92+
 * @link https://blog.chromium.org/2021/05/update-on-user-agent-string-reduction.html
 * @link https://www.chromium.org/updates/ua-reduction/
 */
export default function isPlatformCompatible(minChromeVersion: number = 60): boolean {
  // Try with navigator.userAgentData (chrome 90+)
  // Note: Fails in DevTools device emulation
  if (navigator.userAgentData) {
    const brand = navigator.userAgentData.brands.find(brand =>
      brand.brand === 'Chromium' ||
      brand.brand === 'Google Chrome'
    )

    // Assume non-chrome, but an evergreen browser
    if (!brand) {
      return true
    }

    return Number.parseFloat(brand.version) >= minChromeVersion
  }

  // Deprecated in Chrome ~107
  const chromeVMatch = navigator.userAgent.match(/\sChrome\/([^\s]+)/)

  // It's Chrome
  if (chromeVMatch) {
    const semVer = Number.parseFloat(chromeVMatch[1])

    // Compare Chrome version
    return semVer >= minChromeVersion && semVer !== 0
  }

  // Assume non-chrome evergreen browser
  return true
}

/**
 * Chrome 76+
 */
export function isSystemColorModeSupported() {
  return window.matchMedia('(prefers-color-scheme)').media === '(prefers-color-scheme)'
}
