import type { LatLngBounds } from 'leaflet'

import type {
  IStationsList,
  IStationListItem,
  IStationsReadingList,
} from '../../models/Station'
import type { TSimpleCoords } from '../../utils/geo'

import { getDistance } from '../../utils/geo'

/**
 * Find nearest station by comparing coordinats
 */
export function selectNearestStation(stations: IStationsList, coords: TSimpleCoords): IStationListItem | null {
  if (!stations.length) {
    return null
  }

  // Compute distances for each station
  const distances = stations.map(station => getDistance(coords, station.coordinates))

  const nearestDistance = Math.min(...distances)
  const nearestIndex = distances.indexOf(nearestDistance)

  return stations[nearestIndex]
}

/**
 * Filter stations by search term
 * Note: indexOf is faster than includes
 * Note: Intl doesn't implement search https://github.com/tc39/ecma402/issues/506
 */
export function selectWithText(
  stations: IStationsList,
  query: string,
  language: string = 'pl',
  minQueryLength = 3,
): IStationsList {
  // Make sure city is provided
  const stationsWithCity = stations.filter(station => station.city)

  // Validate query
  if (!query || query.length < minQueryLength) {
    return stationsWithCity
  }

  query = query.toLocaleLowerCase(language)

  // Lookup in city, then in street
  return stationsWithCity.filter(station =>
    // City
    (station.city && station.city.toLocaleLowerCase(language).indexOf(query) !== -1) ||
    // Street
    (station.address && station.address.toLocaleLowerCase(language).indexOf(query) !== -1) ||
    // Both
    ([station.city, station.address].join(', ').toLocaleLowerCase(language).indexOf(query) !== -1)
  )
}

/**
 * Sort by distance to given point
 */
export function sortByDistance(stations: IStationsList, coords: TSimpleCoords): IStationsList {
  const stationsWithDistance = stations.map(station => ({
    station,
    distance: getDistance(coords, station.coordinates)
  }))

  const sorted = stationsWithDistance.sort(
    (stationWithDistance1, stationWithDistance2) => {
      return stationWithDistance1.distance - stationWithDistance2.distance
    }
  )

  return sorted.map(({ station }) => station)
}

/**
 * Sort by addresss
 */
export function sortByAddress(stations: IStationsList, language: string = 'pl'): IStationsList {
  const intlCollator = new Intl.Collator(language)

  return stations
    .sort((stationListItemA, stationListItemB) =>
      intlCollator.compare(stationListItemA.city ?? '', stationListItemB.city ?? '') ||
      intlCollator.compare(stationListItemA.address ?? '', stationListItemB.address ?? '')
    )
}

/**
 * Filter stations reading to use only stations used by stations list,
 * Which is filtered by source
 * Note: CAQI is available only for GIOŚ stations
 */
export function filterStationsReadingList(
  stationsReading: IStationsReadingList,
  stations: IStationsList,
  latLngBounds: LatLngBounds
): IStationsReadingList {
  // Set of IDs
  const ids = new Set(stations.map(station => station.id))

  return stationsReading.filter(stationReading =>
    ids.has(stationReading.device.id) &&
    latLngBounds.contains(stationReading.device.coordinates)
  )
}
