import { useState, useEffect } from 'react'

import type { TMediaBreakpoint } from '../constants/mediaBreakpoint'
import { mediaBreakpointQueries } from '../constants/mediaBreakpoint'

/**
 * Use breakpoints
 * @see https://usehooks.com/useMedia/
 * @example
 * const isScreenLarge = useBreakpoint('lg')
 */
export default function useBreakpoint(when: TMediaBreakpoint) {
  const [ matches, setMatches ] = useState<boolean>(false)

  useEffect(() => {
    const mediaQuery = mediaBreakpointQueries.get(when)

    if (!mediaQuery) {
      return
    }

    const mediaList = window.matchMedia(mediaQuery)
    const updateMatches = () => setMatches(mediaList.matches)

    updateMatches()

    // Safari < 14 compat
    if (!mediaList.addEventListener) {
      mediaList.addListener(updateMatches) // eslint-disable-line deprecation/deprecation
      return () => mediaList.removeListener(updateMatches) // eslint-disable-line deprecation/deprecation
    }

    mediaList.addEventListener('change', updateMatches)
    return () => mediaList.removeEventListener('change', updateMatches)
  }, [when])

  return matches
}
