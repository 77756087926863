import useSWR from 'swr'
import type { SWRConfiguration } from 'swr'
import { useMemo } from 'react'

import type { TUseResourceReturnType } from '../models/useApi'
import type { IStationsReadingList, IStationsReadingItem } from '../models/Station'
import type { TSensorType } from '../models/sensorType';
import { MINUTES } from '../constants/time'
import TransferError from '../utils/Error/TransferError'

const apiRefreshInterval = 30 * MINUTES

const fallbackData: IStationsReadingList = []

/**
 * Fetch single sensor reading for all stations
 * Note: There is no source flag to filter by
 */
export default function useStationsReadingList(
  shouldFetch: boolean = true,
  sensorType: TSensorType,
  config?: SWRConfiguration<IStationsReadingList, TransferError>
): TUseResourceReturnType<IStationsReadingList, IStationsReadingList> {
  const endpoint = `v2/public/data/${sensorType}`

  const { data, error, isValidating, mutate } = useSWR<IStationsReadingList, TransferError>(shouldFetch ? endpoint : null, {
    refreshInterval: apiRefreshInterval,
    dedupingInterval: apiRefreshInterval,
    fallbackData,
    ...config,
  })

  return {
    data: useMemo(() => sanitize(data!), [data]),
    error,
    endpoint,
    isLoading: data === fallbackData && !error,
    isValidating,
    isFallbackData: data === fallbackData,
    mutate,
  }
}

/**
 * Sanitize input. For some reason stations may be duplicated (1226)
 */
function sanitize(items: IStationsReadingList): IStationsReadingList {
  // Create hashmap to remove duplicates
  const map = new Map<number, IStationsReadingItem>()

  for (const item of items) {
    map.set(item.device.id, item)
  }

  return Array.from(map.values())
}
