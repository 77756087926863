/**
 * i18n configuration
 * @link https://www.i18next.com/overview/configuration-options
 */

import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

// Load resources without translation
import plResources from './locales/pl/translation.json'

export const defaultNS = 'translation'

export const resources = {
  pl: { translation: plResources },
} as const

/**
 * I18n instance
 * @see https://www.i18next.com/overview/configuration-options
 */
i18n
  .use(initReactI18next)
  .init({
    debug: (process.env.NODE_ENV === 'development'),

    lng: 'pl',

    // Manually load resources
    defaultNS,
    resources,

    // Not needed for react as it escapes by default
    interpolation: {
      escapeValue: false,
    },

    initImmediate: true,

    /**
     * Silence console error 'i18next::pluralResolver: Your environment seems not to be Intl API compatible, ...'
     * However cannot use plural suffixes which are v4+
     * @link https://www.i18next.com/how-to/faq#why-are-my-plural-keys-not-working
     */
    compatibilityJSON: 'v3',

    /** @see https://react.i18next.com/latest/i18next-instance */
    react: {
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'sub'],
      useSuspense: false,
    }
  }, (err, t) => {
    // Initialized and ready to go!
  })

export default i18n
