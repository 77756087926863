import { useState, useEffect } from 'react'
import { Capacitor } from '@capacitor/core'
import { nativeCrash } from '@sentry/capacitor'
import type { Mode } from '@ionic/core'
import {
  getConfig,
  IonList,
  IonListHeader,
  IonItem,
  IonLabel,
} from '@ionic/react'

/**
 * Service menu
 * backport ionic popover v6 dismissOnSelect
 */
const ServiceMenu: React.FC<{
  dismiss: () => void,
}> = ({
  dismiss: dismissPopover,
}) => {
  const isDevEnv = process.env.NODE_ENV === 'development'

  // Detect current mode
  const [ platformMode ] = useState<Mode>(() => getConfig()!.get('mode'))

  // Detect availabilities
  const isFullscreenAvailable: boolean = document.fullscreenEnabled === true

  const [ isFullscreen, setIsFullscreen ] = useState<boolean>(isFullscreenAvailable && document.fullscreenElement !== null)
  const [ swRegistration, setSwRegistration ] = useState<ServiceWorkerRegistration | undefined>(undefined)

  // React to fullscreen changes (user esc key press)
  useEffect(() => {
    const handleDocumentFullscreenChange = () =>
      setIsFullscreen(document.fullscreenElement !== null)

    document.addEventListener('fullscreenchange', handleDocumentFullscreenChange)

    return () => document.removeEventListener('fullscreenchange', handleDocumentFullscreenChange)
  }, [])

  // Detect service worker update method availability
  useEffect(() => {
    !isDevEnv && navigator.serviceWorker && navigator.serviceWorker.getRegistration()
      .then(setSwRegistration)
  }, [isDevEnv])

  /**
   * Handle restart button click
   */
  const handleRestartClick = (event: React.MouseEvent<HTMLIonItemElement, MouseEvent>): void => {
    window.location.reload()

    dismissPopover()
  }

  /**
   * Handle update check button click
   * Note: No need to update state (disable button during check) as menu is closed anyway
   */
  const handleUpdateCheckClick = (event: React.MouseEvent<HTMLIonItemElement, MouseEvent>): void => {
    swRegistration?.update()
      .catch()

    dismissPopover()
  }

  /**
   * Handle fullscreen toggle
   * N/A on iPhone
   */
  const handleFullscreenToggle = (event: React.MouseEvent<HTMLIonItemElement, MouseEvent>): void => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen({ navigationUI: 'hide'}).catch(() => {})
    } else {
      document.exitFullscreen()
    }

    dismissPopover()
  }

  /**
   * Trigger a crash in the native sentry client, regular error
   */
  const handleThrowErrorClick = (): void => {
    // Assume sentry enableNative option is true
    if (Capacitor.isNativePlatform()) {
      nativeCrash()
    } else {
      throw new Error('Test Thrown Error')
    }
  }

  return (
    <IonList lines="full">
      <IonListHeader>
        {'Service menu'}
      </IonListHeader>
      {/** App version */}
      <IonItem detail={false}>
        <IonLabel>
          v{process.env.REACT_APP_VERSION} ({process.env.NODE_ENV})
        </IonLabel>
      </IonItem>
      {/** Restart */}
      <IonItem
        button
        detail={false}
        onClick={handleRestartClick}
      >
        <IonLabel>
          {'Restart'}
        </IonLabel>
      </IonItem>
      {/** Disable MSW */}
      <IonItem
        button
        href={'/?disable-mocks=true'}
        detail={false}
        disabled={!isDevEnv}
        onClick={dismissPopover}
      >
        <IonLabel>
          {'Restart with MSW Off'}
        </IonLabel>
      </IonItem>
      {/** Force iOS/ MD platform mode */}
      <IonItem
        button
        href={platformMode === 'md'
          ? '/?ionic:mode=ios'
          : '/?ionic:mode=md'
        }
        detail={false}
        onClick={dismissPopover}
      >
        <IonLabel>
          {platformMode === 'md'
            ? 'Restart to iOS mode'
            : 'Restart to MD mode'
          }
        </IonLabel>
      </IonItem>
      {/** Update check */}
      <IonItem
        button
        detail={false}
        disabled={!swRegistration}
        onClick={handleUpdateCheckClick}
      >
        <IonLabel>
          {'Check for update'}
        </IonLabel>
      </IonItem>
      {/** Toggle fullscreen */}
      <IonItem
        button
        detail={false}
        disabled={!isFullscreenAvailable}
        onClick={handleFullscreenToggle}
      >
        <IonLabel>
          {`Toggle fullscreen ${isFullscreen ? 'Off' : 'On'}`}
        </IonLabel>
      </IonItem>
      {/** Throw an error */}
      <IonItem
        button
        detail={false}
        onClick={handleThrowErrorClick}
      >
        <IonLabel>
          {'Throw an Error'}
        </IonLabel>
      </IonItem>
      {/** Close */}
      <IonItem
        lines="none"
        button
        detail={false}
        onClick={dismissPopover}
      >
        <IonLabel>
          {'Close'}
        </IonLabel>
      </IonItem>
    </IonList>
  )
}

export default ServiceMenu
