import type { ISyncAction } from '../../models/actions'
import type { IFavStations } from './favstations.types'
import initialState from './favstations.initialstate'
import TYPE from './favstations.actionstypes'

export default function reducer (state: IFavStations = initialState, action: ISyncAction): IFavStations {
  switch (action.type) {
    // Toggle
    case TYPE.TOGGLE: {
      return !state.includes(action.payload)
        // Add
        ? [ ...state, action.payload ]
        // Remove
        : state.filter(stationCmp => stationCmp !== action.payload)
    }

    // Add
    case TYPE.ADD:
      return [ ...state, action.payload ]

    // Remove
    case TYPE.REMOVE:
      return state.filter(station => station !== action.payload)

    // Reorder
    case TYPE.REORDER:
      // Clone & sort by provided order
      return state
        .slice()
        .sort((idA: number, idB: number) =>
          action.payload.indexOf(idA) - action.payload.indexOf(idB)
        )
  }

  return state
}
