/**
 * Action creators
 */
import type { ISyncAction } from '../../models/actions'
import type { TSensorType } from '../../models/sensorType'
import type { TColorMode } from '../../constants/colorMode'
import TYPE from './settings.actiontypes'

export const toggleClosestDevice = (): ISyncAction => ({
  type: TYPE.CLOSEST_DEVICE_TOGGLE,
})

export const setColorMode = (colorMode: TColorMode): ISyncAction<TColorMode> => ({
  type: TYPE.COLOR_MODE_SET,
  payload: colorMode,
})

export const toggleSensor = (sensorType: TSensorType, state: boolean): ISyncAction<TSensorType> => ({
  type: state
    ? TYPE.SENSOR_ADD
    : TYPE.SENSOR_REMOVE,
  payload: sensorType,
})
