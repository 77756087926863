import { memo, useEffect } from 'react'
import type { Map, LeafletEvent, LatLngBoundsLiteral } from 'leaflet'
import { useMapEvents } from 'react-leaflet'

import { useStore } from '../../state'
import { setBounds } from '../../state/map'

/**
 * Map events component
 * @link https://react-leaflet.js.org/docs/example-external-state/
 */
const MapEvents: React.FC<{
  setZoomDistantAt: number,
}> = ({
  setZoomDistantAt = 8,
}) => {
  // Register event handler
  const map = useMapEvents({
    moveend: handleBoundsChange,
  })

  // Action dispatcher
  const dispatch = useStore(state => state.dispatch)

  useEffect(() => {
    setZoomClassName(map, setZoomDistantAt)
  }, [map, setZoomDistantAt])

  /**
   * Fired for both move and zoom
   */
  function handleBoundsChange (event: LeafletEvent) {
    // Convert to literal
    const mapBounds = map.getBounds()

    const northEast = mapBounds.getNorthEast()
    const southWest = mapBounds.getSouthWest()

    const latLngBounds: LatLngBoundsLiteral = [
      [northEast.lat, northEast.lng],
      [southWest.lat, southWest.lng],
    ]

    dispatch(setBounds(latLngBounds))

    setZoomClassName(map, setZoomDistantAt)
  }

  return null
}

/**
 * Add zoom class name to map element
 */
function setZoomClassName(map: Map, toggleAt: number): void {
  map.getContainer()
    .classList.toggle('syn-map-container--zoom-distant', map.getZoom() <= toggleAt)

  /*
  // Via opacity (icon + shadow)
  map.eachLayer(layer =>
    layer instanceof Marker && layer instanceof MarkerCluster === false &&
      layer.setOpacity(map.getZoom() <= toggleAt ? 0 : 1)
  )
  */
}

export default memo(MapEvents)
