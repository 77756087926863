import { useIonPopover, createGesture } from '@ionic/react'
import { useRef, useEffect } from 'react'
import clsx from 'clsx'

import ServiceMenu from '../ServiceMenu/ServiceMenu'
import { ReactComponent as Logo } from './images/icon-monochrome.svg'

import './Logotype.css'

const LogoType: React.FC<{
  className?: string,
  size?: number,
  serviceMenu?: boolean,
}> = ({
  className,
  size = 24,
  serviceMenu = false,
}) => {
  const logoRef = useRef(null)

  const [ present, dismiss ] = useIonPopover(ServiceMenu, {
    dismiss: () => dismiss(),
  })

  /**
   * Handle Double click
   * Note: dblclick & contextmenu events are not supported on iOS 13.1
   * @link https://ionicframework.com/docs/utilities/gestures#double-click-gesture
   */
  useEffect(() => {
    if (!serviceMenu || !logoRef.current) {
      return
    }

    const DOUBLE_CLICK_THRESHOLD = 500
    let lastOnStart = 0

    const gesture = createGesture({
      el: logoRef.current,
      threshold: 0,
      gestureName: 'dblclick-gesture',
      onStart(detail) {
        const now = Date.now()
        const isDblclick = Math.abs(now - lastOnStart) <= DOUBLE_CLICK_THRESHOLD

        lastOnStart = isDblclick ? 0 : now
        isDblclick && present({ event: detail.event })
      },
    })

    gesture.enable()

    return () => gesture.destroy()
  }, [serviceMenu, present])

  return (
    <div
      ref={logoRef}
      className={clsx('syn-logotype', className)}
    >
      <Logo
        className="syn-logotype__logo"
        width={size}
        height={size}
        viewBox="0 0 512 512"
      />
      <span className="syn-logotype__brand">
        {'Syngeos'}
      </span>
    </div>
  )
}


export default LogoType
