import { useState, useEffect, useMemo, useRef } from 'react'
import type { KeyedMutator } from 'swr'
import type { WithTranslation } from 'react-i18next'
import { withTranslation } from 'react-i18next'
import type { AlertButton } from '@ionic/react'
import {
  IonAlert,
  useIonRouter,
} from '@ionic/react'

import { TransferError, ConnectError } from '../../utils/Error'
import { routes } from '../../navigation'

import './TransferErrorAlert.css'

/**
 * Handle Connect/ API error
 */
const TransferErrorAlert: React.FC<WithTranslation & {
  error: TransferError,
  mutate: KeyedMutator<any>,
}> = ({
  error,
  mutate,
  t,
}) => {
  const isMountedRef = useRef<boolean>(true)
  const [ isRetry, setIsRetry ] = useState<boolean>(false)

  const { push } = useIonRouter()

  const ionAlertRef = useRef<HTMLIonAlertElement>(null)

  useEffect(() => () => { isMountedRef.current = false }, [])

  /**
   * Handle cancel button click, allow closing modal
   */
  const handleCancelButtonClick = (): void =>
    push(routes.dashboard, 'root', undefined)

  /**
   * Handle retry button click, prevent closing modal
   */
  const handleRetryButtonClick = (): boolean => {
    if (!isRetry) {
      setIsRetry(true)

      mutate()
        .finally(() => isMountedRef.current && setIsRetry(false))
    }

    return false
  }

  // Error info
  const ionAlertProps = useMemo(
    () => ({
      header: t(error.tMessageKey) as string,
      message: error.tDescriptionKey ? t(error.tDescriptionKey) as string : undefined,
    }),
    [error, t]
  )

  // Modal buttons
  const cancelButton: AlertButton = {
    text: t('ui.button.cancel'),
    handler: handleCancelButtonClick,
  }

  const retryButton: AlertButton = {
    text: t('ui.button.retry'),
    cssClass: isRetry ? 'syn-alert-button-disabled' : undefined,
    handler: handleRetryButtonClick,
  }

  const buttons: AlertButton[] = error instanceof ConnectError
    ? [cancelButton, retryButton]
    : [cancelButton]

  // Trick to update memoized ionic alert button cssClass
  useEffect(() => {
    if (ionAlertRef.current) {
      ionAlertRef.current.buttons = buttons
    }
  }, [isRetry]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <IonAlert
      ref={ionAlertRef}
      header={ionAlertProps.header}
      message={ionAlertProps.message}
      buttons={buttons}
      backdropDismiss={false}
      keyboardClose={false}
      isOpen={true}
    />
  )
}

export default withTranslation()(TransferErrorAlert)
