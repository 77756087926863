import {
  homeSharp, homeOutline,
  mapSharp, mapOutline,
  settingsSharp, settingsOutline,
} from 'ionicons/icons'

import type { IMenuItems } from '.'

import routes from './routes'

const menuItems: IMenuItems = [
  {
    title: 'dashboard',
    url: routes.dashboard,
    mdIcon: homeSharp,
    iosIcon: homeOutline,
  },
  {
    title: 'map',
    url: routes.map,
    mdIcon: mapSharp,
    iosIcon: mapOutline,
  },
  {
    title: 'settings',
    url: routes.settings,
    mdIcon: settingsSharp,
    iosIcon: settingsOutline,
  },
]

export default menuItems
