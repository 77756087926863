import type { PointExpression } from 'leaflet'
import {
  DivIcon,
  point,
} from 'leaflet'

import type { TSensorDataCurrentNorm } from '../../models/Sensor'
import { currentNormGrades } from '../../constants/normGrade'

import './MarkerIcon.css'

export default abstract class MarkerIcon extends DivIcon {
  /**
   * Set icon current norm className
   */
  protected setIconCurrentNorm(element: HTMLElement, currentNorm: TSensorDataCurrentNorm): this {
    const currentNormClassName = `syn-map-marker--grade-${currentNorm}`

    // No-op
    if (element.classList.contains(currentNormClassName)) {
      return this
    }

    // Remove previous
    if (element.classList.length) {
      element.classList.remove(...currentNormGrades.map(gradeKey => `syn-map-marker--grade-${gradeKey}`))
    }

    // Add current
    element.classList.add(currentNormClassName)

    return this
  }

  /**
   * @see Icon._setIconStyles
   */
  protected setIconStyles(element: HTMLElement, sizeExpression: PointExpression): this {
    const size = point(sizeExpression)
    const anchor = size.divideBy(2)

    // Set anchor
    element.style.marginLeft = `${-anchor.x}px`
    element.style.marginTop = `${-anchor.y}px`

    // Set size
    element.style.width = `${size.x}px`
    element.style.height = `${size.y}px`

    return this
  }
}
