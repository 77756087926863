import useSWR from 'swr'
import type { SWRConfiguration } from 'swr'

import type { TUseResourceReturnType } from '../models/useApi'
import type { IStation } from '../models/Station'

import { MINUTES } from '../constants/time'
import TransferError from '../utils/Error/TransferError'

const apiRefreshInterval = 6 * MINUTES

const fallbackData = undefined

/**
 * Fetch station historical data
 * Same as fetch station, but with data for last 24h
 */
export default function useStationHistorical (
  stationId: number,
  shouldFetch: boolean = true,
  config?: SWRConfiguration<IStation<string>, TransferError>,
): TUseResourceReturnType<IStation<string>, typeof fallbackData> {
  const endpoint = `public/data/device/${stationId}/historical`

  const { data, error, isValidating, mutate } = useSWR<IStation<string>, TransferError>(shouldFetch ? endpoint : null, {
    refreshInterval: apiRefreshInterval,
    dedupingInterval: apiRefreshInterval,
    fallbackData,
    ...config,
  })

  return {
    data,
    error,
    endpoint,
    isLoading: data === fallbackData && !error,
    isValidating,
    isFallbackData: data === fallbackData,
    mutate,
  }
}
