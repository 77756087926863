import type { i18n as Ti18n } from 'i18next'

import type { IStationSensor, IStationSensorWithNorm } from '../models/Sensor'
import type { TSensorType, TGuideSensorType, TWeatherSensorType } from '../models/sensorType'

import { guideSensorTypes, weatherSensorTypes } from '../constants/sensorType'

/**
 * Norms guard
 */
export function isWithNorm(stationSensor: IStationSensor): stationSensor is IStationSensorWithNorm {
  return 'norm' in stationSensor
}

/**
 * First with norm guard
 */
export function isFirstWithNorm(stationSensor: IStationSensor, stationSensors: IStationSensor[]): stationSensor is IStationSensorWithNorm {
  return (
    isWithNorm(stationSensor) &&
    stationSensor === stationSensors.find(isWithNorm)
  )
}

/**
 * Check if sensor has weather icon.
 * Exclude sensors with norms as these are displayed separately
 */
export function isWeatherType(stationSensor: IStationSensor): boolean {
  return (
    isWeatherSensorType(stationSensor.name) &&
    !isWithNorm(stationSensor)
  )
}

/**
 * Custom guard
 */
function isWeatherSensorType(sensorType: TSensorType | any): sensorType is TWeatherSensorType {
  return weatherSensorTypes.includes(sensorType)
}

/**
 * Custom guard checking key prefix
 * - code: Chemical formula
 * - label
 * - name
 * - description
 */
export function hasInfo(i18n: Ti18n, sensorType: TGuideSensorType | any): sensorType is TGuideSensorType {
  return (
    guideSensorTypes.includes(sensorType) &&
    i18n.exists(`sensorType.${sensorType}.label`) &&
    i18n.exists(`sensorType.${sensorType}.name`) &&
    i18n.exists(`sensorType.${sensorType}.desc`)
  )
}
