/**
 * Note: dateStyle and timeStyle are supported on Chrome 72+
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 */
export const timeOptions: Intl.DateTimeFormatOptions = {
  hour: 'numeric',
  minute: '2-digit',
}

export const dateTimeOptions: Intl.DateTimeFormatOptions = {
  ...timeOptions,
  month: 'short',
  day: 'numeric',
}

/**
 * Check if dates have same day
 */
export function isSameDay (dateA: Date, dateB: Date): boolean {
  return (
    dateA.getFullYear() === dateB.getFullYear() &&
    dateA.getMonth() === dateB.getMonth() &&
    dateA.getDate() === dateB.getDate()
  )
}
