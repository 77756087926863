import { useEffect } from 'react'
import type { RouteComponentProps } from 'react-router'
import type { WithTranslation } from 'react-i18next'
import { withTranslation } from 'react-i18next'
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
} from '@ionic/react'
import { Capacitor } from '@capacitor/core'
import { captureMessage } from '@sentry/react'

import { routes } from '../../navigation'

import Page from '../../components/Page/Page'

/**
 * Route not found page
 * TODO: Add Sentry event on native builds
 */
const NotFoundPage: React.FC<RouteComponentProps & WithTranslation> = ({
  match,
  t,
}) => {
  // Report on native platforms
  useEffect(() => {
    if (Capacitor.isNativePlatform() && match.path !== '/') {
      captureMessage('Path not found', {
        level: 'warning',
        extra: { path: match.path }
      })
    }
  }, [match.path])

  return (
    <Page
      id="notfound-page"
      title={t('page.NotFound.title')}
    >
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>
            {t('page.NotFound.message', { replace: { url: match.url }})}
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <IonButton
            expand="block"
            color="primary"
            fill="solid"
            routerLink={routes.dashboard}
          >
            {t('ui.button.home')}
          </IonButton>
        </IonCardContent>
      </IonCard>
    </Page>
  )
}

export default withTranslation()(NotFoundPage)
