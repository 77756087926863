import { create } from 'zustand'
import { devtools, persist, redux, createJSONStorage } from 'zustand/middleware'

import getStorage from './StateStorageFactory'
import sentryMiddleware from '../utils/sentry/state-middleware'

import type { IAppState } from './types'
import type { ISyncAction } from '../models/actions'

import { initialState as favStations } from './favstations'
import { initialState as map } from './map'
import { initialState as settings } from './settings'

import { favStationsReducer } from './favstations'
import { mapReducer } from './map'
import { settingsReducer } from './settings'

const initialState: IAppState = {
  favStations,
  map,
  settings,
}

/**
 * Root reducer
 */
const rootReducer = (state: IAppState, action: ISyncAction): IAppState => ({
  favStations: favStationsReducer(state.favStations, action),
  map: mapReducer(state.map, action),
  settings: settingsReducer(state.settings, action),
})

/**
 * Store
 */
export const useStore = create(devtools(persist(sentryMiddleware(redux(rootReducer, initialState)), {
  name: 'state',
  storage: createJSONStorage(getStorage),
  // Note: Migration is not triggered when version matches or is missing
  version: 0,
})))
