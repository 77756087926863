import type { TSensorDataCurrentNorm } from '../models/Sensor'

/**
 * i18n labels
 * - grade-a: "Bardzo dobry"
 * - grade-b: "Dobry"
 * - grade-c: "Umiarkowany"
 * - grade-d: "Dostateczny"
 * - grade-e: "Zły"
 * - grade-f: "Bardzo zły"
 * - unknown: "Nieznany"
 *
 * i18n labels for noise
 * - grade-a: "Strefa komfortu"
 * - grade-c: "Strefa umiarkowana"
 * - grade-e: "Strefa głośna"
 */
enum NORM_GRADE {
  A = 'grade_a',
  B = 'grade_b',
  C = 'grade_c',
  D = 'grade_d',
  E = 'grade_e',
  F = 'grade_f',
}

export default NORM_GRADE

export const currentNormGrades: ReadonlyArray<TSensorDataCurrentNorm> = [
  'grade-a',
  'grade-b',
  'grade-c',
  'grade-d',
  'grade-e',
  'grade-f',
] as const
