import { useState, useEffect } from 'react'
import { Capacitor } from '@capacitor/core'
import { App as CapacitorApp } from '@capacitor/app'
import { SplashScreen } from "@capacitor/splash-screen"
import type { BackButtonEvent } from '@ionic/core'
import { useIonRouter } from '@ionic/react'
import type { WithTranslation } from 'react-i18next'
import { withTranslation } from 'react-i18next'

import type { ShortcutItem } from 'capacitor-android-shortcuts'
import { AndroidShortcuts } from 'capacitor-android-shortcuts'

import { routes, generatePath } from '../../navigation'
import { useStore } from '../../state'

/**
 * Native platform handlers:
 * - Splash screen
 * - Shortcuts (Android)
 */
const NativeHandlers: React.FC<WithTranslation> = ({
  t,
}) => {
  const { canGoBack, push } = useIonRouter()
  const favStations = useStore(state => state.favStations)

  const [ isAndroidShortcutSupported, setIsAndroidShortcutSupported ] = useState<boolean>(false)

  useEffect(() => {
    /**
     * Hide Splash screen (default auto hide duration is 500ms) when app is ready for interaction
     * Might be handled in SplashPage when sure it's rendered
     */
    SplashScreen.hide()

    /**
     * Handle back button
     * Event fired only on android platform (iOS has no harware back button)
     * @link https://ionicframework.com/docs/v5/developing/hardware-back-button#exiting-the-app
     */
    document.addEventListener('ionBackButton', handleIonBackButton)

    return () => document.removeEventListener('ionBackButton', handleIonBackButton)

    function handleIonBackButton (ev: BackButtonEvent) {
      // Note: Priority -1 doesn't work here
      ev.detail.register(0, (processNextHandler) => {
        // Exit
        if (!canGoBack()) {
          // Android only
          CapacitorApp.exitApp()
        // Navigate back
        } else {
          processNextHandler()
        }
      })
    }
  }, [canGoBack])

  // Resolve shortcut support
  useEffect(() => {
    Capacitor.getPlatform() === 'android' && AndroidShortcuts.isDynamicSupported()
      .then(({ result }) => setIsAndroidShortcutSupported(result))
  }, [])

  // Listen to shortut tap
  useEffect(() => {
    if (isAndroidShortcutSupported) {
      AndroidShortcuts.addListener('shortcut', ({ data: path }) => push(path, 'root'))

      return () => void AndroidShortcuts.removeAllListeners()
    }
  }, [isAndroidShortcutSupported]) // eslint-disable-line react-hooks/exhaustive-deps

  // Set shortcuts, see public/manifest.json
  useEffect(() => {
    if (isAndroidShortcutSupported) {
      // Static shortcut
      const mapShortcutItem: ShortcutItem = {
        id: '/map',
        shortLabel: t('ui.shortcut.map'),
        longLabel: t('ui.shortcut.map'),
        data: generatePath(routes.map),
        icon: {
          type: 'Resource',
          name: '@drawable/ic_shortcut_bookmark',
        },
      }

      // Dynamic shortcuts
      const stationShortcutItems: ShortcutItem[] = favStations
        .slice(0, 3)
        .map((id, index) => ({
          id: `/stations/${id}`,
          shortLabel: t('ui.shortcut.favStation', { replace: { index: index + 1 } }),
          longLabel: t('ui.shortcut.favStation', { replace: { index: index + 1 } }),
          data: generatePath(routes.station, { id }),
          icon: {
            type: 'Resource',
            name: '@drawable/ic_shortcut_map',
          },
        }))

      AndroidShortcuts.setDynamic({ items: [mapShortcutItem, ...stationShortcutItems] })
    }
  }, [isAndroidShortcutSupported, favStations, t])

  return null
}

export default withTranslation()(NativeHandlers)
