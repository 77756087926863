import type { WithTranslation } from 'react-i18next'
import { withTranslation } from 'react-i18next'
import {
  IonTabs,
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonLabel,
} from '@ionic/react'

import type { IMenuItems } from '../../navigation'
import useBreakpoint from '../../hooks/useBreakpoint'

/**
 * IonTabs wrapper with IonTabBar
 * IonTabBar must be present in same component as IonTabs
 * @see https://github.com/ionic-team/ionic-framework/issues/21541
 */
const TabWrapper: React.FC<WithTranslation & {
  /** Menu items */
  items: IMenuItems,
  /** Disable tabs, which break ionic split pane when shown */
  disabled?: boolean,
}> = ({
  items,
  children,
  disabled = false,
  t,
}) => {
  const showLabel = useBreakpoint('sm')

  // Note: Need to wrap in fragment, or split view will freak out
  if (disabled) {
    return <>{children}</>
  }

  return (
    <IonTabs>
      {children}
      <IonTabBar slot="bottom">
        {items.map((item, index) =>
          <IonTabButton
            key={index}
            tab={index.toString()}
            href={item.url}
            layout={showLabel ? 'icon-top' : 'label-hide'}
          >
            <IonIcon md={item.mdIcon} ios={item.iosIcon} />
            <IonLabel>
              {t(`component.Menu.items.${item.title}`)}
            </IonLabel>
          </IonTabButton>
        )}
      </IonTabBar>
    </IonTabs>
  )
}

export default withTranslation()(TabWrapper)
