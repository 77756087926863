import { useState, useRef, useEffect } from 'react'

/**
 * @param {string} mediaQueryString - For example (prefers-color-scheme: dark)
 * @return {boolean} False when doesn't match or isn't supported (Safari < 13)
 */
export default function useMediaQuery(mediaQueryString: string): boolean {
  const mediaListRef = useRef(window.matchMedia(mediaQueryString))
  const [ matches, setMatches ] = useState<boolean>(mediaListRef.current.matches)

  useEffect(() => {
    const mediaList = mediaListRef.current

    const updateMatches = () => setMatches(mediaList.matches)

    // Safari < 14 compat
    if (!mediaList.addEventListener) {
      mediaList.addListener(updateMatches) // eslint-disable-line deprecation/deprecation
      return () => mediaList.removeListener(updateMatches) // eslint-disable-line deprecation/deprecation
    }

    mediaList.addEventListener('change', updateMatches)
    return () => mediaList.removeEventListener('change', updateMatches)
  }, [])

  return matches
}
