import useSWR from 'swr'
import type { SWRConfiguration } from 'swr'
import { useMemo } from 'react'

import type { TUseResourceReturnType } from '../models/useApi'
import type { IStationsList } from '../models/Station'
import { MINUTES } from '../constants/time'
import STATION_SOURCE from '../constants/stationSource'
import TransferError from '../utils/Error/TransferError'

// See aqi:sync:devices cron task
// Fresh devices list available every 1 minute
const apiRefreshInterval = 30 * MINUTES

const fallbackData: IStationsList = []

/**
 * Fetch stations list
 */
export default function useStationsList (
  shouldFetch: boolean = true,
  config?: SWRConfiguration<IStationsList, TransferError>
): TUseResourceReturnType<IStationsList, IStationsList> {
  const endpoint = 'public/devices'

  const { data, error, isValidating, mutate } = useSWR<IStationsList, TransferError>(shouldFetch ? endpoint : null, {
    refreshInterval: apiRefreshInterval,
    dedupingInterval: apiRefreshInterval,
    fallbackData,
    ...config,
  })

  const dataProcessed = useMemo(
    () => processData(data as typeof fallbackData),
    [data]
  )

  return {
    data: dataProcessed,
    error,
    endpoint,
    isLoading: data === fallbackData && !error,
    isValidating,
    isFallbackData: data === fallbackData,
    mutate,
  }
}

/**
 * Whitelist station sources
 */
const filterStationSources: STATION_SOURCE[] = [
  STATION_SOURCE.IOT,
  STATION_SOURCE.GIOS,
  // STATION_SOURCE.AIRQLAB,
  // STATION_SOURCE.OPENAQ,
  STATION_SOURCE.EKOSLUPEK,
  // STATION_SOURCE.LORAWAN,
  STATION_SOURCE.NASK,
]


/**
 * Process incoming data
 */
function processData(stationsList: IStationsList): IStationsList {
  return stationsList
    // Filter stations by source
    .filter(stationListItem =>
      filterStationSources.includes(stationListItem.source)
    )
}
