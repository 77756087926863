import type { ParseKeys, CustomTypeOptions } from 'i18next'

/**
 * ES2022
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error/Error#parameters
 */
export type TErrorOptions = {
  /** Original error cause */
  cause?: Error
}

/**
 * Based on Guzzle Exceptions
 * @see https://docs.guzzlephp.org/en/stable/quickstart.html#exceptions
 *
 * Note: First SWR error is not an instanceof TransferError
 *       when fetch is invoked immediately on page mount
 */
export default abstract class TransferError extends Error {
  /** Message translation key */
  tMessageKey: ParseKeys<CustomTypeOptions['defaultNS']> = 'error.TransferError.message'
  tDescriptionKey?: ParseKeys<CustomTypeOptions['defaultNS']>

  /**
   * @inheritdoc
   */
  constructor(
    public request: Request,
    message?: string,
    options?: TErrorOptions,
  ) {
    // @ts-ignore
    super(message, options)

    // V8 only, doesn't prepend cause
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, new.target)
    }

    /**
     * Set the prototype explicitly on when compilation target is ES5.
     * @see https://www.typescriptlang.org/docs/handbook/2/classes.html#inheriting-built-in-types
     */
    Object.setPrototypeOf(this, TransferError.prototype)
  }
}

TransferError.prototype.name = 'TransferError'
