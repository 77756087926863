import {
  IonPage,
  IonSpinner,
  IonText,
} from '@ionic/react'
import type { Color } from '@ionic/core'

import './SplashPage.css'

/**
 * Splash page
 */
const SplashPage: React.FC<{
  text?: string,
  color?: Color,
}> = ({
  text = undefined,
  color = 'primary',
}) => (
  <IonPage
    id="splash-page"
    className="ion-justify-content-center ion-align-items-center"
  >
    <IonSpinner
      color={color}
      className="syn-splash-spinner"
    />
    {text &&
      <IonText className="syn-splash-text ion-margin-top">
        {text}
      </IonText>
    }
  </IonPage>
)

export default SplashPage
