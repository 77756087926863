import type { ISettings } from './settings.types'
import SENSOR_TYPE from '../../constants/sensorType'
import COLOR_MODE from '../../constants/colorMode'

const initialState: ISettings = {
  closestDevice: false,
  colorMode: COLOR_MODE.LIGHT,
  sensors: [
    SENSOR_TYPE.PM2_5,
    SENSOR_TYPE.TEMPERATURE,
  ],
}

export default initialState
