import type { ISyncAction } from '../../models/actions'
import type { ISettings } from './settings.types'
import initialState from './settings.initialstate'
import TYPE from './settings.actiontypes'

export default function reducer (state: ISettings = initialState, action: ISyncAction): ISettings {
  switch (action.type) {
    // Toggle closest device
    case TYPE.CLOSEST_DEVICE_TOGGLE:
      return {
        ...state,
        closestDevice: !state.closestDevice,
      }

    // Set color mode
    case TYPE.COLOR_MODE_SET: {
      return {
        ...state,
        colorMode: action.payload,
      }
    }

    // Remove sensor
    case TYPE.SENSOR_REMOVE:
      return {
        ...state,
        sensors: state.sensors.filter(sensorType =>
          sensorType !== action.payload
        ),
      }

    // Add sensor
    case TYPE.SENSOR_ADD:
      return {
        ...state,
        sensors: [...state.sensors, action.payload],
      }
  }

  return state
}
