import COLOR_MODE from '../constants/colorMode'
import { useStore } from '../state'
import useMediaQuery from './useMediaQuery'

/**
 * Dark mode/ scheme on/ off
 */
export default function useDarkMode(): boolean {
  const settings = useStore(state => state.settings)
  const prefersDark = useMediaQuery('(prefers-color-scheme: dark)')

  return isDarkMode(settings.colorMode, prefersDark)
}

/**
 * Determine color mode preferred x system
 */
function isDarkMode (colorMode: COLOR_MODE = COLOR_MODE.SYSTEM, prefersDark: boolean): boolean {
  switch (colorMode) {
    case COLOR_MODE.LIGHT:
      return false

    case COLOR_MODE.DARK:
      return true

    case COLOR_MODE.SYSTEM:
    default:
      return prefersDark
  }
}
