import type { ISyncAction } from '../../models/actions'
import type { IMap } from './map.types'
import initialState from './map.initialstate'
import TYPE from './map.actiontypes'

export default function reducer (state: IMap = initialState, action: ISyncAction): IMap {
  switch (action.type) {
    case TYPE.SET_BOUNDS:
      return {
        ...state,
        bounds: action.payload,
      }
  }

  return state
}
