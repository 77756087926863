import React from 'react'
import ReactDOM from 'react-dom'
import { createBrowserHistory } from 'history'
import * as Sentry from '@sentry/capacitor'
import { init as sentryReactInit, reactRouterV5Instrumentation } from '@sentry/react'
import { BrowserTracing } from '@sentry/browser'
import { Capacitor } from '@capacitor/core'
import { SplashScreen } from "@capacitor/splash-screen"

import App from './App'
import isPlatformCompatible from './utils/compat'
import { beforeBreadcrumb } from './utils/sentry/configuration'
import { routeConfig, matchPath } from './navigation'

import './polyfills'

// Create browser history, just as @ionic/react-router would do
const history = createBrowserHistory()

if (
  process.env.NODE_ENV === 'development' &&
  window.location.search.indexOf('disable-mocks') === -1
) {
  import('./mocks/browser')
    .then(({ worker }) => worker.start({
      // Warn about unhandled requests to API, ignore rest (assets, chrome-extension)
      // @link https://github.com/mswjs/msw/discussions/978
      onUnhandledRequest(request, print) {
        if (request.url.href.startsWith(process.env.REACT_APP_API_URL)) {
          print.warning()
        }
      },
    }))
}

/**
 * Initialize Sentry
 * @link https://docs.sentry.io/platforms/javascript/configuration/
 */
Sentry.init({
  enabled: process.env.REACT_APP_SENTRY_ENABLED === '1',

  // Common Options
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: process.env.REACT_APP_SENTRY_RELEASE,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  dist: Capacitor.getPlatform(),

  // Integration Configuration
  integrations: [
    // @link https://docs.sentry.io/platforms/javascript/guides/react/performance/instrumentation/automatic-instrumentation/
    new BrowserTracing({
      tracingOrigins: ['localhost', process.env.REACT_APP_API_URL],
      routingInstrumentation: reactRouterV5Instrumentation(history, routeConfig, matchPath),
    }),
  ],

  // Hooks
  beforeBreadcrumb,

  // Tracing Options
  tracesSampleRate: Number.parseFloat(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE),

  // Hybrid SDK Options
  /** Disable false OutOfMemory reports @link https://docs.sentry.io/platforms/apple/guides/ios/configuration/out-of-memory/ */
  enableOutOfMemoryTracking: false,

  // Filtering
  ignoreErrors: [
    'AbortError',
    // Safe to ignore
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications.',
  ],
}, sentryReactInit)

const rootElement = document.getElementById('root')!

// Check platform compatibility
if (isPlatformCompatible(60)) {
  // React 18 with Concurrent Mode
  // ReactDOM.createRoot(rootElement).render(...)

  ReactDOM.render(
    <React.StrictMode>
      <App
        env={process.env}
        history={history}
      />
    </React.StrictMode>,
    rootElement
  )
} else {
  rootElement.innerHTML = `
    <div class="syn-platform-incompatibile ion-page hydrated">
      <ion-card>
        <ion-card-header>
          <ion-card-title>
            Platform not compatible
          </ion-card-title>
        </ion-card-header>
        <ion-card-content>
          Please update <a target="_system" href="https://www.google.com/chrome/update/">your browser</a>.
        </ion-card-content>
      </ion-card>
    </div>
  `

  SplashScreen.hide()
}

// Set preferred color scheme immediately; Will be overwritten by ColorModeSwitcher component when state loads
document.body.classList.toggle('dark', window.matchMedia('(prefers-color-scheme: dark)').matches)

// Web vitals handled by @sentry/tracing which is installed as a dependency of @sentry/capacitor
// Initialized with Sentry.init
