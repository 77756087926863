import type { WithTranslation } from 'react-i18next'
import { withTranslation } from 'react-i18next'
import { IonSpinner } from '@ionic/react'

import type {
  IStationSensorWithNorm,
  ISensorDataWithNorm,
} from '../../models/Sensor'

import ReadingDate from '../ReadingDate/ReadingDate'

import './Chart.css'

/**
 * Responsive SVG chart
 * @link https://css-tricks.com/how-to-make-charts-with-svg/#aa-spark-lines
 * @link https://codepen.io/mdgrover/pen/eZENxO
 */
const Chart: React.FC<{
  historicalData: IStationSensorWithNorm | undefined,
  height?: number,
  isLoading?: boolean,
} & WithTranslation> = ({
  historicalData,
  height = 125,
  isLoading = false,
  t,
}) => {
  const maxValue = historicalData ? Math.max(...historicalData.data.map(data => data.value)) : undefined

  return (
    <div className="syn-chart">
      {isLoading
        ? <IonSpinner
            className="syn-chart__spinner"
            color="primary"
          />
        : historicalData
          ?
            <svg
              className="syn-chart__element"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="100%"
              height={height}
              role="img"
            >
              {historicalData.data.map((sensorData, index) =>
                <ChartItem
                  key={sensorData.read_at}
                  data={sensorData}
                  index={index}
                  itemsLength={historicalData.data.length}
                  maxValue={maxValue!}
                  chartHeight={height}
                />
              )}
            </svg>
          :
            <div className="syn-chart__no-data">
              {t('page.Station.noData')}
            </div>
      }
    </div>
  )
}

type TChartItemEvent = (data: ISensorDataWithNorm, index: number) => void

/**
 * Chart item
 */
const ChartItem: React.FC<{
  /** Data item */
  data: ISensorDataWithNorm,
  /** Index */
  index: number,
  /** Number of items */
  itemsLength: number,
  /** Max value */
  maxValue: number,
  /** Chart height in % */
  chartHeight: number,
  /** Vertical margin in % */
  margin?: number,
  /** X Axis height */
  xAxisHeight?: number,
  /** Mouse enter handler */
  onEnter?: TChartItemEvent,
  /** Mouse click handler */
  onClick?: TChartItemEvent,
}> = ({
  data,
  index,
  itemsLength,
  maxValue,
  chartHeight,
  margin = 0.3,
  xAxisHeight = 20,
  onEnter = undefined,
  onClick = undefined,
}) => {
  const xRelative = index / itemsLength * 100

  const widthRelative = 100 / itemsLength
  // Percentage minus label height
  const heightRelative = data.value / maxValue * (1 - xAxisHeight / chartHeight) * 100

  return (
    <g
      className="syn-chart-item"
      onMouseEnter={() => onEnter && onEnter(data, index)}
      onClick={() => onClick && onClick(data, index)}
    >
      <rect
        className="syn-chart-item__crosshair"
        x={`${xRelative}%`}
        y={xAxisHeight}
        height="100%"
        width={`${widthRelative}%`}
        fill="transparent"
      />
      <rect
        className={`syn-chart-item__bar syn-chart-item--grade-${data.current_norm ?? 'unknown'}`}
        x={`${xRelative + widthRelative * margin}%`}
        y={xAxisHeight}
        width={`${widthRelative * (1 - margin * 2)}%`}
        height={`${heightRelative}%`}
        rx="4"
        ry="4"
        stroke="transparent"
        strokeWidth={`${widthRelative * margin}%`}
      />
      <text
        className="syn-chart-item__label"
        x={`${xRelative}%`}
        y={-xAxisHeight / 2}
        dx={`${widthRelative / 2}%`}
        dy=".5ch"
      >
        <ReadingDate dateIso8601={data.read_at} type="time" />
      </text>
    </g>
  )
}

export default withTranslation()(Chart)
