/**
 * Sentry breadcrumbs middleware
 * Based on..
 * @link https://github.com/getsentry/sentry-javascript/blob/master/packages/react/src/redux.ts
 * @link https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/redux/
 *
 * ...and
 * @link https://github.com/pmndrs/zustand/discussions/788
 * @link https://github.com/pmndrs/zustand/blob/main/docs/guides/typescript.md#middleware-that-doesnt-change-the-store-type
 */
import type { StateCreator, StoreMutatorIdentifier, Mutate, StoreApi } from 'zustand'
import type { Context } from '@sentry/types'
import { configureScope } from '@sentry/core'

import type { ISyncAction } from '../../models/actions'

const ACTION_BREADCRUMB_CATEGORY = 'redux.action'
const ACTION_BREADCRUMB_TYPE = 'info'
const STATE_CONTEXT_KEY = 'redux.state'

declare module 'zustand' {
  interface StoreMutators<S, A> {
    sentry: Write<Cast<S, object>, { dispatch: (a: A) => A }>
  }
}

type SentryMiddleware =
  <
    T extends unknown,
    Mps extends [StoreMutatorIdentifier, unknown][] = [],
    Mcs extends [StoreMutatorIdentifier, unknown][] = []
  >
    (
      f: StateCreator<T, [...Mps, ['sentry', unknown]], Mcs>
    ) =>
      StateCreator<T, Mps, [['sentry', unknown], ...Mcs]>


type SentryMiddlewareImpl =
  <T extends unknown & Context>
    (f: StateCreator<T, [], []>) => StateCreator<T, [], []>

const sentryMiddleware: SentryMiddlewareImpl = (f) => (set, get, _store) => {
  type T = ReturnType<typeof f>

  const store = _store as Mutate<StoreApi<T>, [['sentry', ISyncAction]]>

  const initialState = f(set, get, _store)
  const originalDispatch = store.dispatch

  store.dispatch = (action) => {
    const dispatchResult = originalDispatch(action)
    const { dispatch, ...newState } = get()

    configureScope(scope => {
      // Action breadcrumbs
      action && scope.addBreadcrumb({
        category: ACTION_BREADCRUMB_CATEGORY,
        data: action,
        type: ACTION_BREADCRUMB_TYPE,
      })

      // Set latest state to scope
      scope.setContext(STATE_CONTEXT_KEY, newState)
    })

    return dispatchResult
  }

  return initialState
}

export default sentryMiddleware as unknown as SentryMiddleware

type Write<T extends object, U extends object> = Omit<T, keyof U> & U
type Cast<T, U> = T extends U ? T : U;
