/**
 * Action creators
 */
import type { ISyncAction } from '../../models/actions'
import TYPE from './favstations.actionstypes'

export const add = (stationId: number): TFavStationAction => ({
  type: TYPE.ADD,
  payload: stationId,
})

export const remove = (stationId: number): TFavStationAction => ({
  type: TYPE.REMOVE,
  payload: stationId,
})

export const toggle = (stationId: number): TFavStationAction => ({
  type: TYPE.TOGGLE,
  payload: stationId,
})

export const reorder = (stationIds: number[]): ISyncAction<number[]> => ({
  type: TYPE.REORDER,
  payload: stationIds,
})

type TFavStationAction = ISyncAction<number>
