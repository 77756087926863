import { useMemo } from 'react'
import type { WithTranslation } from 'react-i18next'
import { withTranslation } from 'react-i18next'

import type { SelectChangeEventDetail } from '@ionic/core'
import {
  IonCheckbox,
  IonItem,
  IonItemGroup,
  IonLabel,
  IonList,
  IonListHeader,
  IonToggle,
  IonSelect,
  IonSelectOption,
} from '@ionic/react'

import { useStore } from '../../state'

import { sensorTypes } from '../../constants/sensorType'
import COLOR_MODE from '../../constants/colorMode'
import { isSystemColorModeSupported } from '../../utils/compat'

import {
  toggleClosestDevice,
  setColorMode,
  toggleSensor,
} from '../../state/settings'

import SensorType from '../../components/SensorType/SensorType'

import Page from '../../components/Page/Page'

import './SettingsPage.css'

/**
 * Settings page
 */
const SettingsPage: React.FC<WithTranslation> = ({
  t,
}) => {
  // Global state
  const settings = useStore(state => state.settings)
  const dispatch = useStore(state => state.dispatch)

  const isSystemColorModeAvailable = useMemo(isSystemColorModeSupported, [])

  /**
   * Handle dark/ night mode change
   */
  const handleColorModeChange = (event: CustomEvent<SelectChangeEventDetail<COLOR_MODE>>): void => {
    dispatch(setColorMode(event.detail.value))
  }

  return (
    <Page
      id="settings-page"
      title={t('page.Settings.title')}
    >
      <IonList lines="full">
        {/** Nearest station */}
        <IonItem>
          <IonLabel>
            {t('page.Settings.setting.showNearestStation.label')}
          </IonLabel>
          <IonToggle
            checked={settings.closestDevice}
            onIonChange={() => dispatch(toggleClosestDevice())}
          />
        </IonItem>

        {/** Color mode */}
        <IonItem>
          <IonLabel>
            {t('page.Settings.setting.colorMode.label')}
          </IonLabel>
          <IonSelect
            value={settings.colorMode ?? COLOR_MODE.SYSTEM}
            interface="alert"
            okText={t('ui.button.ok')}
            cancelText={t('ui.button.cancel')}
            onIonChange={handleColorModeChange}
          >
            {isSystemColorModeAvailable &&
              <IonSelectOption value={COLOR_MODE.SYSTEM}>
                {t('page.Settings.setting.colorMode.options.system')}
              </IonSelectOption>
            }
            <IonSelectOption value={COLOR_MODE.LIGHT}>
              {t('page.Settings.setting.colorMode.options.light')}
            </IonSelectOption>
            <IonSelectOption value={COLOR_MODE.DARK}>
              {t('page.Settings.setting.colorMode.options.dark')}
            </IonSelectOption>
          </IonSelect>
        </IonItem>

        {/** Sensor types */}
        <IonItemGroup>
          <IonListHeader>
            <IonLabel>
              {t('page.Settings.setting.sensorTypes.label')}
            </IonLabel>
          </IonListHeader>
          {sensorTypes.map(sensorType =>
            <IonItem key={sensorType}>
              <IonLabel>
                <SensorType sensorType={sensorType} />
              </IonLabel>
              <IonCheckbox
                value={sensorType}
                checked={settings.sensors.includes(sensorType)}
                slot="end"
                onIonChange={event => dispatch(toggleSensor(event.detail.value, event.detail.checked))}
              />
            </IonItem>
          )}
        </IonItemGroup>
      </IonList>
    </Page>
  )
}

export default withTranslation()(SettingsPage)
