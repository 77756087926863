import type { DivIconOptions } from 'leaflet'
import type { Position } from '@capacitor/geolocation'

import MarkerIcon from './MarkerIcon'

import './MyLocationIcon.css'

/**
 * My location iocn
 */
export default class MyLocationIcon extends MarkerIcon {
  /**
   * @inheritdoc
   */
  constructor (
    protected geolocationPosition: Position,
    size: number = 18,
    options?: DivIconOptions
  ) {
    super({
      iconSize: [size, size],
      className: 'syn-map-marker-my-location-icon',
      html: '<span class="syn-map-marker-my-location-icon__inner"></span>',
      ...options,
    })
  }
}

/**
 * My location icon factory function
 */
export function myLocationIcon(...params: ConstructorParameters<typeof MyLocationIcon>): MyLocationIcon {
  return new MyLocationIcon(...params)
}
