import type { IStationSensorBasic } from '../../models/Sensor'

import SensorType from '../SensorType/SensorType'

import './StationReading.css'

/**
 * Station/ Dashboard reading without norm
 */
const StationReadingBasic: React.FC<{
  stationSensor: IStationSensorBasic,
}> = ({
  stationSensor,
}) =>
  <div className="syn-dashboard-reading">
    {/** Meta */}
    <div className="syn-dashboard-reading__description">
      <div className="syn-station-reading-sensor-type">
        <SensorType sensorType={stationSensor.name} />
      </div>
    </div>
    {/** Data */}
    <div className="syn-dashboard-reading__data syn-station-reading-basic">
      <span className="syn-station-reading-basic__value">
        {stationSensor.data.length
          ? stationSensor.data[0].value.toFixed(0)
          : '—'
        }
      </span>
      <span className="syn-station-reading-basic__unit">
        {stationSensor.unit}
      </span>
    </div>
  </div>

export default StationReadingBasic
