/**
 * @link https://www.i18next.com/translation-function/formatting#datetime
 */

import { memo } from 'react'
import type { WithTranslation } from 'react-i18next'
import { withTranslation } from 'react-i18next'

import {
  timeOptions,
  dateTimeOptions,
} from '../../utils/format/date'

/**
 * Formatted reading date
 */
const ReadingDate: React.FC<WithTranslation & {
  dateIso8601: string,
  type: 'datetime' | 'time'
  fallback?: string,
}> = ({
  dateIso8601,
  type,
  fallback = '-',
  t,
}) => {
  const date = new Date(dateIso8601)

  // Check for Invalid Date
  const isValid = !Number.isNaN(date)

  const options = type === 'time'
    ? timeOptions
    : dateTimeOptions

  return (
    <>
      {isValid
        ? t('lc.intlDateTime', { val: date, formatParams: { val: options } })
        : fallback
      }
    </>
  )
}

// Use memo and assume reading date will update across days
export default memo(withTranslation()(ReadingDate))
