import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
} from '@ionic/react'

import { useTranslation } from 'react-i18next'
import type { FallbackRender } from '@sentry/react/types/errorboundary'

import { isStableAppRelease } from '../../utils/version'
import { routes } from '../../navigation'

import Page from '../../components/Page/Page'

import './ErrorPage.css'

/**
 * Fallback renderer factory with tranlation property
 * As it's not possible to use withTranslation HOC
 * @link https://docs.sentry.io/platforms/javascript/guides/react/components/errorboundary/
 */
export const errorPageRenderer: FallbackRender = errorData => <ErrorPage {...errorData} />

/**
 * See Sentry ErrorBoundary props
 */
type FallbackProps = Parameters<FallbackRender>[0]

/**
 * Error page
 */
const ErrorPage: React.FC<FallbackProps> = ({
  error,
  resetError,
}) => {
  const { t } = useTranslation()

  // Note: Somehow helmets titleTemplate isn't used
  return (
    <Page
      id="error-page"
      title={t('page.default.titleTemplate', { replace: { title: t('page.Error.title') }})}
    >
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>
            {t('page.Error.message', { replace: { error }})}
          </IonCardTitle>
        </IonCardHeader>

        <IonCardContent>
          {/** Provide error details in debug mode */}
          {!isStableAppRelease &&
            <details className="ion-margin-bottom">
              <summary>
                {error.name}: {error.message}
              </summary>
              <div className="syn-error-stacktrace ion-margin-top">
                {error.stack}
              </div>
            </details>
          }

          {/** Go gome */}
          <IonButton
            color="primary"
            routerLink={routes.dashboard}
          >
            {t('ui.button.home')}
          </IonButton>

          {/** Restart */}
          <IonButton
            color="light"
            fill="solid"
            onClick={() => window.location.reload()}
          >
            {t('page.Error.button.restart')}
          </IonButton>

          {/** Ignore */}
          {resetError &&
            <IonButton
              color="light"
              fill="solid"
              onClick={() => resetError()}
            >
              {t('page.Error.button.ignore')}
            </IonButton>
          }
        </IonCardContent>
      </IonCard>
    </Page>
  )
}

export default ErrorPage
