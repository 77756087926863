import { useMemo, useState, useRef } from 'react'
import type { RouteComponentProps } from 'react-router'
import type { WithTranslation } from 'react-i18next'
import { withTranslation } from 'react-i18next'
import {
  IonList,
  IonItem,
  IonLabel,
  IonRefresher,
  IonRefresherContent,
  IonToolbar,
  IonIcon,
  IonSearchbar,
  IonButton,
} from '@ionic/react'
import type { RefresherEventDetail, SearchbarChangeEventDetail } from '@ionic/core'
import {
  addCircle, addCircleOutline,
  searchSharp, searchOutline,
  closeSharp, closeOutline,
} from 'ionicons/icons'
import { Virtuoso } from 'react-virtuoso'

import { routes } from '../../navigation'
import useIonViewVisibility from '../../hooks/useIonViewVisibility'
import { useStore } from '../../state'
import useStationsList from '../../data/stationsList'
import { useCurrentPosition } from '../../hooks/useGeolocation'
import { selectWithText, sortByAddress, sortByDistance } from '../../data/selectors/selectStations'

import { toggle as toggleStation } from '../../state/favstations'

import Page from '../../components/Page/Page'
import LoadingOverlay from '../../components/LoadingOverlay/LoadingOverlay'
import TransferErrorAlert from '../../components/TransferErrorAlert/TransferErrorAlert'

import './StationsPage.css'

interface StationsPageProps extends WithTranslation, RouteComponentProps<{}> {}

/**
 * Stations list page
 * For react-virtuoso see https://beta.ionicframework.com/docs/react/virtual-scroll
 */
const StationsPage: React.FC<StationsPageProps> = ({
  history,
  t,
  i18n,
}) => {
  // Page visibility
  const isVisible = useIonViewVisibility()

  // Data
  const { data: stations, error, isLoading, isFallbackData, mutate } = useStationsList(isVisible)

  // Global state
  const settings = useStore(state => state.settings)
  const favStations = useStore(state => state.favStations)
  const dispatch = useStore(state => state.dispatch)

  // Search bar ref
  const ionSearchbarRef = useRef<HTMLIonSearchbarElement>(null)

  // Get current position, while ignoring error
  const { currentPosition } = useCurrentPosition(settings.closestDevice)

  const [ searchText, setSearchText ] = useState<string>('')

  const [ isSearchbarVisible, setIsSearchbarVisible ] = useState<boolean>(false)

  const [ isListAtTop, setIsListAtTop ] = useState<boolean>(true)

  /**
   * Filter stations
   */
  const filteredStations = useMemo(
    () => selectWithText(stations, searchText, i18n.language),
    [stations, searchText, i18n.language]
  )

  // Sort stations
  const sortedStations = useMemo(
    () =>
      currentPosition
        ? sortByDistance(filteredStations, [ currentPosition.coords.latitude, currentPosition.coords.longitude ])
        : sortByAddress(filteredStations, i18n.language),
    [filteredStations, currentPosition, i18n.language]
  )

  // Assign last processed list
  const stationsList = sortedStations

  // Pull to refresh
  const refresh = (event: CustomEvent<RefresherEventDetail>): void => {
    mutate()
      .finally(() => event.detail.complete())
  }

  // Select station
  const handleSelectStation = (stationId: number): void => {
    dispatch(toggleStation(stationId))

    history.push(routes.dashboard)
  }

  /**
   * Handle searchbar value change
   * Note: By default there's a 250ms debounce
   * Note: May use `await scheduler.postTask(() => setSearchText(event.detail.value!), {priority: 'user-visible'})` in chromium 94+
   * @see https://www.chromestatus.com/feature/6031161734201344
   */
  const handleSearchbarChange = (event: CustomEvent<SearchbarChangeEventDetail>): void =>
    setSearchText(event.detail.value!)

  /**
   * Toggle searchbar visibility
   */
  const handleSearchbarToggle = (): void => {
    // Toggle search bar
    setIsSearchbarVisible(!isSearchbarVisible)

    // Set focus on show
    if (!isSearchbarVisible && ionSearchbarRef.current) {
      ionSearchbarRef.current.setFocus()
    }

    // Reset on close when no results (treaat as cancel)
    if (isSearchbarVisible && !filteredStations.length) {
      setSearchText('')
    }
  }

  return (
    <Page
      id="stations-page"
      title={t('page.Stations.title')}
      toolbarPrimaryButton={
        <IonButton onClick={handleSearchbarToggle}>
          <IonIcon
            slot="icon-only"
            md={isSearchbarVisible ? closeSharp : searchSharp}
            ios={isSearchbarVisible ? closeOutline: searchOutline}
          />
        </IonButton>
      }
      secondaryToolbar={
        <IonToolbar hidden={!isSearchbarVisible} className="syn-toolbar-searchbar">
          <IonLabel className="syn-toolbar-searchbar__label">
            {t('page.Stations.search')}
          </IonLabel>
          <IonSearchbar
            className="syn-toolbar-searchbar__searchbar"
            ref={ionSearchbarRef}
            autocomplete="address-level2"
            autocorrect="on"
            inputmode="search"
            placeholder={t('page.Stations.city')}
            value={searchText}
            showCancelButton="never"
            showClearButton="never"
            onIonChange={handleSearchbarChange}
          />
        </IonToolbar>
      }
      contentProps={{ scrollY: false }}
    >
      <IonRefresher
        slot="fixed"
        onIonRefresh={refresh}
        disabled={!isListAtTop}
      >
        <IonRefresherContent />
      </IonRefresher>

      <IonList
        className="syn-stations-list"
        lines="inset"
      >
        <Virtuoso
          className="ion-content-scroll-host"
          data={stationsList}
          defaultItemHeight={80}
          atTopStateChange={setIsListAtTop}
          itemContent={(index, station) =>
            <div className="syn-stations-list__item-wrapper">
              <IonItem
                key={station.id}
                button={true}
                detail={false}
                onClick={() => handleSelectStation(station.id)}
              >
                <IonIcon
                  slot="end"
                  icon={favStations.includes(station.id) ? addCircle : addCircleOutline}
                  color="secondary"
                  size="large"
                />
                <IonLabel>
                  <div className="syn-station-city">
                    {station.city}
                  </div>
                  {station.address &&
                    <div className="syn-station-address">
                      {station.address}
                    </div>
                  }
                </IonLabel>
              </IonItem>
            </div>
          }
        />
      </IonList>

      {/** Loading */}
      <LoadingOverlay isOpen={isLoading && isVisible} />

      {/** Error dialog */}
      {error && isFallbackData && isVisible &&
        <TransferErrorAlert
          error={error}
          mutate={mutate}
        />
      }
    </Page>
  )
}

export default withTranslation()(StationsPage)
