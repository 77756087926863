import { IonSkeletonText } from '@ionic/react'

import './SkeletonText.css'

/**
 * Wrapper around IonSkeletonText
 * @link https://ionicframework.com/docs/v5/api/skeleton-text
 */
const SkeletonText: React.FC<{
  width?: string,
  height?: string,
}> = ({
  width,
  height,
}) =>
  <IonSkeletonText
    className="syn-skeleton-text"
    style={{
      width,
      lineHeight: height,
    }}
  />

export default SkeletonText
