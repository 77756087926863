import type { History } from 'history'
import { Redirect, Route } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ErrorBoundary } from '@sentry/react'
import {
  IonApp,
  IonRouterOutlet,
  IonSplitPane,
  setupIonicReact,
} from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import { chevronBack } from 'ionicons/icons'
import { HelmetProvider } from 'react-helmet-async'

import { routes, menuItems } from './navigation'
import { AppStateProvider } from './state/AppStateContext'
import { ApiProvider } from './data/ApiContext'
import useBreakpoint from './hooks/useBreakpoint'
import Menu from './components/Menu/Menu'
import TabsWrapper from './components/TabsWrapper/TabsWrapper'
import ServiceWorkerHandler from './components/ServiceWorker/ServiceWorkerHandler'
import NativeHandlers from './components/NativeHandlers/NativeHandlers'
import { ColorWheelDefs } from './components/ColorWheel'
import ColorModeSwitcher from './components/ColorModeSwitcher/ColorModeSwitcher'

import SplashPage from './pages/Splash/SplashPage'
import DashboardPage from './pages/Dashboard/DashboardPage'
import StationsPage from './pages/Stations/StationsPage'
import StationPage from './pages/Station/StationPage'
import FavStationPage from './pages/FavStation/FavStationPage'
import GuidePage from './pages/Guide/GuidePage'
import MapPage from './pages/Map/MapPage'
import SettingsPage from './pages/Settings/SettingsPage'
import NotFoundPage from './pages/NotFound/NotFoundPage'
import { errorPageRenderer } from './pages/Error/ErrorPage'

// Note: Do not use named import withou using it or webpack will tree-shake it
import './i18n'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

/* Theme variables */
import './theme/variables.css'

/* Custom */
import './App.css'
import './theme/theme.css'
import './fonts/Neris/neris.css'

setupIonicReact({
  backButtonIcon: chevronBack,
  backButtonText: '',
})

/**
 * App
 */
const App: React.FC = () => {
  const showSplitPane = useBreakpoint('lg')

  return (
    <IonSplitPane contentId="main">
      <Menu items={menuItems} disabled={!showSplitPane} />
      <TabsWrapper items={menuItems} disabled={showSplitPane}>
        <IonRouterOutlet id="main">
          <Redirect exact from="/" to={routes.dashboard} />
          <Route path={routes.dashboard} exact component={DashboardPage} />
          <Route path={routes.stations} exact component={StationsPage} />
          <Route path={routes.station} exact component={StationPage} />
          <Route path={routes.favStation} exact component={FavStationPage} />
          <Route path={routes.guide} exact component={GuidePage} />
          <Route path={routes.map} exact component={MapPage} />
          <Route path={routes.settings} exact component={SettingsPage} />
          <Route component={NotFoundPage} />
        </IonRouterOutlet>
      </TabsWrapper>
    </IonSplitPane>
  )
}

/**
 * App with providers
 */
const AppWrapper: React.FC<{
  env: NodeJS.ProcessEnv,
  history?: History,
}> = ({
  env,
  history,
}) => {
  const { t } = useTranslation()

  return (
    <IonApp>
      <HelmetProvider>
        <ErrorBoundary fallback={errorPageRenderer}>
          <ServiceWorkerHandler>
            <AppStateProvider fallback={<SplashPage text={t('ui.initializing.state')} />}>
              <ColorModeSwitcher />
              <ApiProvider
                fallback={<SplashPage text={t('ui.initializing.cache')} />}
                baseUrl={env.REACT_APP_API_URL}
              >
                <IonReactRouter history={history}>
                  <App />
                  <NativeHandlers />
                  <ColorWheelDefs />
                </IonReactRouter>
              </ApiProvider>
            </AppStateProvider>
          </ServiceWorkerHandler>
        </ErrorBoundary>
      </HelmetProvider>
    </IonApp>
  )
}

export default AppWrapper
