import { useMemo, memo } from 'react'
import type { Position } from '@capacitor/geolocation'
import type { LatLngExpression, LeafletMouseEvent } from 'leaflet'
import {
  Marker as LeafletMarker,
  Circle,
  LayerGroup,
  useMap,
} from 'react-leaflet'

import { myLocationIcon } from '../MarkerIcon/MyLocationIcon'

import './MyLocationMarker.css'

/**
 * My location marker
 * @link https://github.com/domoritz/leaflet-locatecontrol
 */
const MyLocationMarker: React.FC<{
  geolocationPosition: Position,
}> = ({
  geolocationPosition,
}) => {
  const map = useMap()

  const coords: LatLngExpression = useMemo(() =>
    [geolocationPosition.coords.latitude, geolocationPosition.coords.longitude],
    [geolocationPosition.coords]
  )

  /**
   * Handle click
   */
  const handleClick = (leafletMouseEvent: LeafletMouseEvent): void => {
    map.flyTo(leafletMouseEvent.latlng)
  }

  return (
    <LayerGroup>
      <LeafletMarker
        position={coords}
        icon={myLocationIcon(geolocationPosition)}
        eventHandlers={{ click: handleClick }}
        zIndexOffset={20}
      />
      <Circle
        center={coords}
        radius={geolocationPosition.coords.accuracy}
        className="syn-map-marker-my-location-circle"
      />
    </LayerGroup>
  )
}

export default memo(MyLocationMarker, ({ geolocationPosition: prevPosition }, { geolocationPosition: nextPosition }) =>
  prevPosition.coords.latitude === nextPosition.coords.latitude &&
  prevPosition.coords.longitude === nextPosition.coords.longitude &&
  prevPosition.coords.accuracy === nextPosition.coords.accuracy
)
