import type { RouteComponentProps } from 'react-router'
import { Redirect } from 'react-router-dom'
import { useIonToast } from '@ionic/react'
import type { WithTranslation } from 'react-i18next'
import { withTranslation } from 'react-i18next'

import { routes, generatePath } from '../../navigation'
import { useStore } from '../../state'
import { SECONDS } from '../../constants/time'

interface FavStationPageProps extends WithTranslation, RouteComponentProps<{
  index: string,
}> {}

/**
 * Favorite/ selected station page
 */
const FavStationPage: React.FC<FavStationPageProps> = ({
  match,
  history,
  t,
}) => {
  // Resolve index
  const favStationIndex = Number.parseInt(match.params.index)

  // Global state
  const favStations = useStore(state => state.favStations)

  const [ present, dismiss ] = useIonToast()

  const id = favStations[favStationIndex - 1]

  // Redirect to dashboard, notify user
  if (id === undefined) {
    present({
      message: t('page.FavStation.notFound', { replace: { favStationIndex }}),
      duration: 5 * SECONDS,
      buttons: [{
        text: t('page.FavStation.add'),
        handler: () => {
          history.push(routes.stations)
          dismiss()
        },
      }]
    })

    return <Redirect to={routes.dashboard} />
  }

  // Redirect to station page
  return <Redirect to={generatePath(routes.station, { id })} />
}

export default withTranslation()(FavStationPage)
