import type { RouteConfig } from '@sentry/react/types/reactrouter'

/**
 * Named routes
 */
const routes = {
  dashboard: '/dashboard',
  station: '/stations/:id(\\d+)',
  stations: '/stations',
  favStation: '/fav-stations/:index(\\d+)',
  map: '/map',
  guide: '/guide/:sensorType',
  settings: '/settings',
} as const

export default routes

/**
 * Array of Route Config Objects
 * @link https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
 * @link https://www.npmjs.com/package/react-router-config#route-configuration-shape
 */
export const routeConfig: RouteConfig[] = Object.values(routes).map(path => ({ path }))
