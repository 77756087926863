/**
 * Based on https://github.com/capacitor-community/react-hooks/blob/main/packages/geolocation
 * which were N/A for Capacitor 3.0 at a time
 * @link https://developer.mozilla.org/en-US/docs/Web/API/Geolocation/getCurrentPosition
 * Note: Browser doesn't show Use location dialog when geolocation is disabled (GeolocationPositionError.PERMISSION_DENIED)
 *       `For a better experience, turn on device location, which uses Google's location service: No, thanks | Ok`
 */
import { useEffect, useState, useCallback } from 'react'
import type { Position, PositionOptions } from '@capacitor/geolocation'
import { Geolocation } from '@capacitor/geolocation'

type TResult = {
  /** Current position */
  currentPosition: Position | undefined,
  /** Progress */
  isRequesting: boolean,
  /** Position error. On desktop when offline uses POSITION_UNAVAILABLE (Network location provider at 'https://www.googleapis.com/' : ERR_NAME_NOT_RESOLVED.) */
  error: TPositionError | undefined,
  /** Manual update */
  getPosition: (options?: PositionOptions) => Promise<void>,
}

/**
 * Based on GeolocationPositionError
 * @link https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPositionError
 */
type TPositionError = GeolocationPositionError & {
  code?: number,
  message: string,
}

/**
 * Default @capacitor/geolocation position options
 * on Android emulator enableHighAccuracy must be set true to work, othewise promise never resolves
 * @link https://github.com/ionic-team/capacitor/issues/2854
 */
const defaultOptions: Required<PositionOptions> = {
  enableHighAccuracy: false,
  timeout: 10000, // Note: Ignored on Android since @capacitor/geolocation@^4
  maximumAge: 0,
}

/**
 * Note: adding event listener for permission status is not implemented by @capacitor/geolocation
 * Particularly `PermissionStatus.addEventListener('change')` method is not implemented
 * @link https://developer.mozilla.org/en-US/docs/Web/API/PermissionStatus/onchange
 * Note: Default timeout is 10s
 * @link https://github.com/ionic-team/capacitor-plugins/blob/main/geolocation/src/web.ts
 */
export function useCurrentPosition(
  /** Enable geolocation position request */
  isEnabled: boolean = false,
  /** Position options */
  options?: PositionOptions,
): TResult {
  const [ currentPosition, setCurrentPosition ] = useState<Position | undefined>()
  const [ isRequesting, setIsRequesting ] = useState<boolean>(false)
  const [ error, setError ] = useState<TPositionError | undefined>()

  /**
   * Request and update position
   */
  const getPosition = useCallback((newOptions: PositionOptions | undefined): Promise<void> => {
    setIsRequesting(true)
    setError(undefined)

    const resolvedOptions: Required<PositionOptions> = {
      ...defaultOptions,
      ...options,
      ...newOptions,
    }

    const promises: Promise<Position>[] = []

    let timeoutId: number | undefined

    /**
     * Manually reject promise when GPS sensor is N/A (tablets) or on iOS 14.7.1 PWA when Privacy > Location Services > Safari Websites > Ask Next Time
     * Promise is stuck in pending state for Geolocation.getCurrentPosition and Geolocation.checkPermissions without a prompt
     * Note: Permissions API is N/A in iOS <= 15.4
     * @link https://github.com/ionic-team/capacitor-plugins/issues/822
     * @link https://stackoverflow.com/questions/67924991/navigator-geolocation-getcurrentposition-hangs-on-ios-pwa
     * @link https://support.apple.com/en-il/HT207092
     */
    promises.push(new Promise<never>((resolve, reject) =>
      timeoutId = window.setTimeout(() =>
        reject({ code: 3, message: 'Timeout expired' }),
        resolvedOptions.timeout + 500
      )
    ))

    promises.push(Geolocation.getCurrentPosition(resolvedOptions))

    return Promise.race<Position>(promises)
      .then(setCurrentPosition)
      .catch(setError)
      .finally(() => setIsRequesting(false))
      .finally(() => timeoutId && window.clearTimeout(timeoutId))
  }, [options])

  useEffect(() => {
    if (isEnabled) {
      getPosition(options)
    }
  }, [isEnabled, options, getPosition])

  return {
    currentPosition,
    isRequesting,
    error,
    getPosition,
  }
}
