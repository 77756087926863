import useSWR from 'swr'
import type { SWRConfiguration } from 'swr'

import type { TUseResourceReturnType } from '../models/useApi'
import type { IStation } from '../models/Station'

import { MINUTES } from '../constants/time'
import TransferError from '../utils/Error/TransferError'

// See app:cache:refresh cron task
// Fresh sensor values available every 6 minutes
const apiRefreshInterval = 6 * MINUTES

const fallbackData = undefined

/**
 * Fetch station
 */
export default function useStation (
  stationId: number,
  shouldFetch: boolean = true,
  config?: SWRConfiguration<IStation<string>, TransferError>
): TUseResourceReturnType<IStation<string>, typeof fallbackData> {
  const endpoint = `public/data/device/${stationId}`

  const { data, error, isValidating, mutate } = useSWR<IStation<string>, TransferError>(shouldFetch ? endpoint : null, {
    refreshInterval: apiRefreshInterval,
    dedupingInterval: apiRefreshInterval,
    fallbackData,
    ...config,
  })

  return {
    data,
    error,
    endpoint,
    isLoading: data === fallbackData && !error,
    isValidating,
    isFallbackData: data === fallbackData,
    mutate,
  }
}
