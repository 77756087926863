import React, { useState, useMemo, useRef, useEffect, memo } from 'react'
import type { WithTranslation } from 'react-i18next'
import { withTranslation } from 'react-i18next'
import type { SearchbarChangeEventDetail } from '@ionic/core'
import {
  IonSearchbar,
  IonList,
  IonItem,
  IonLabel,
} from '@ionic/react'
import { useMap } from 'react-leaflet'
import { DomEvent } from 'leaflet'

import type { IStationsList } from '../../models/Station'
import { selectWithText } from '../../data/selectors/selectStations'

import './MapSearch.css'

/**
 * Map search with result hints
 * @link https://material.io/design/navigation/search.html
 */
const MapSearch: React.FC<WithTranslation & {
  stations: IStationsList,
  flyToMinZoom: number,
  currentStationId: number | null,
  setCurrentStationId: React.Dispatch<React.SetStateAction<number | null>>,
  setIsSearchbarActive: React.Dispatch<React.SetStateAction<boolean>>,
}> = ({
  stations,
  flyToMinZoom,
  currentStationId,
  setCurrentStationId,
  setIsSearchbarActive,
  t,
  i18n,
}) => {
  // Limit list results
  const listLimit = 3

  // May be only used as a map descendant
  const map = useMap()

  // Local state
  const [ searchText, setSearchText ] = useState<string>('')

  const elementRef = useRef<HTMLDivElement>(null)

  // Skip leaflet events for control
  useEffect(() => {
    elementRef.current && DomEvent
      .disableClickPropagation(elementRef.current)
      .disableScrollPropagation(elementRef.current)
  }, [])

  // Filter stations
  const filteredSearchStations = useMemo(
    () => searchText ? selectWithText(stations, searchText, i18n.language) : [],
    [stations, searchText, i18n.language]
  )

  // TODO: Sort by position/ address

  // Assign last processed list
  const searchStationsList = filteredSearchStations
  const searchStationsListLimit = searchStationsList.slice(0, listLimit)


  /**
   * Handle searchbar focus
   */
  const handleSearchbarFocus = (event: CustomEvent): void =>
    setIsSearchbarActive(true)

  /**
   * Handle searchbar blur
   */
  const handleSearchbarBlur = (event: CustomEvent): void =>
    setIsSearchbarActive(false)

  /**
   * Handle searchbar change
   */
  const handleSearchbarChange = (event: CustomEvent<SearchbarChangeEventDetail>): void =>
    setSearchText(event.detail.value!)

  /**
   * Handle list item click
   */
  const handleListItemClick = (stationId: number) => {
    const station = searchStationsList.find(item => item.id === stationId)

    if (!station) {
      return
    }

    // May use search results to decide on zoom level.
    const zoom = Math.max(map.getZoom(), flyToMinZoom)

    map.flyTo(station.coordinates, zoom)

    // TODO: Set marker as active
    setCurrentStationId(station.id)
  }

  return (
    <div
      className="syn-map-search"
      ref={elementRef}
    >
      <IonSearchbar
        className="syn-map-search-bar"
        autocomplete="address-level2"
        autocorrect="on"
        inputmode="search"
        placeholder={t('component.MapSearch.placeholder')}
        showClearButton="always"
        value={searchText}
        onIonFocus={handleSearchbarFocus}
        onIonBlur={handleSearchbarBlur}
        onIonChange={handleSearchbarChange}
      />
      {filteredSearchStations.length !== 0 &&
        <IonList
          className="syn-map-search-query-hint-list"
          inset
          lines="full"
        >
          {searchStationsListLimit.map((station, index) =>
            <IonItem
              key={station.id}
              button
              color={station.id === currentStationId ? 'primary' : undefined}
              onClick={() => handleListItemClick(station.id)}
            >
              <IonLabel>
                <div className="syn-station-city">
                  {station.city}
                </div>
                {station.address &&
                  <div className="syn-station-address">
                    {station.address}
                  </div>
                }
              </IonLabel>
            </IonItem>
          )}
          {/** Indicate more results */}
          {searchStationsList.length > searchStationsListLimit.length &&
            <IonItem disabled>
              <IonLabel>
                {t('component.MapSearch.nResults', { replace: { n: searchStationsList.length - searchStationsListLimit.length }})}
              </IonLabel>
            </IonItem>
          }
        </IonList>
      }
    </div>
  )
}

export default memo(withTranslation()(MapSearch))
