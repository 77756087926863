import TransferError from './TransferError'

/**
 * API/ HTTP status code 4xx, 5xx error (BadResponseException in Guzzle)
 */
export default class ApiError extends TransferError {
  /**
   * @inheritdoc
   */
  constructor(
    request: Request,
    public response: Response,
  ) {
    super(request, 'An error occured while fetching the data')

    this.tMessageKey = response.status === 404
      ? 'error.ApiError.404.message'
      : 'error.ApiError.generic.message'

    Object.setPrototypeOf(this, ApiError.prototype)
  }
}

ApiError.prototype.name = 'ApiError'
