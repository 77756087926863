import type { Cache } from 'swr'

/**
 * Delete entry from cache
 * Do not extend Cache interface, as it's not accessible later
 * @see https://github.com/vercel/swr/blob/master/src/utils/serialize.ts
 * @todo Keep eye on 1.2.0 where both err and req will be merged to $swr$ (error, isValidating)
 */
export async function deleteCacheEntry(cache: Cache, key: string): Promise<void> {
  const keys = [
    key,
    // swr 1.0..1.2
    '$err$' + key, // errorKey
    '$req$' + key, // isValidatingKey
    // swr 1.2+
    '$swr$' + key,
  ]

  await Promise.all(keys.map(key =>
    cache.delete(key)
  ))
}
