/**
 * Action creators
 */
import type { ISyncAction } from '../../models/actions'
import type { TBounds } from './map.types'
import TYPE from './map.actiontypes'

export const setBounds = (bounds: TBounds): ISyncAction<TBounds> => ({
  type: TYPE.SET_BOUNDS,
  payload: bounds,
})
