import { useState, useMemo, useCallback, useEffect, useRef } from 'react'
import type { WithTranslation } from 'react-i18next'
import { withTranslation } from 'react-i18next'
import {
  IonFab,
  IonFabButton,
  IonButton,
  IonFabList,
  IonIcon,
  IonToast,
} from '@ionic/react'
import {
  chevronUp,
  locationSharp,
  locationOutline,
} from 'ionicons/icons'
import { MapContainer, TileLayer, AttributionControl } from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-markercluster'
import type { Map, TileLayer as LeafletTileLayer } from 'leaflet'
import { LatLngBounds } from 'leaflet'

import useIonViewVisibility from '../../hooks/useIonViewVisibility'
import type { TSensorType } from '../../models/sensorType'
import SENSOR_TYPE from '../../constants/sensorType'
import useStationsReadingList from '../../data/stationsReadingList'
import useStationsList from '../../data/stationsList'
import { useStore } from '../../state'
import useMediaQuery from '../../hooks/useMediaQuery'
import useTileLoadEvents from '../../hooks/useMapTileEvents'
import { useCurrentPosition } from '../../hooks/useGeolocation'
import { MAP_MAX_BOUNDS } from '../../constants/map'
import useDarkMode from '../../hooks/useDarkMode'

import { filterStationsReadingList } from '../../data/selectors/selectStations'
import { markerClusterIcon } from '../../components/MarkerIcon/MarkerClusterIcon'

import Page from '../../components/Page/Page'
import MapEvents from '../../components/Map/MapEvents'
import StationReadingMarker from '../../components/Marker/StationReadingMarker'
import MyLocationMarker from '../../components/Marker/MyLocationMarker'
import MapSearch from '../../components/Map/MapSearch'
import LoadingOverlay from '../../components/LoadingOverlay/LoadingOverlay'
import TransferErrorAlert from '../../components/TransferErrorAlert/TransferErrorAlert'

import 'leaflet/dist/leaflet.css'
import 'leaflet.markercluster/dist/MarkerCluster.css'

import './MapPage.css'

/**
 * Sensor types availabe for filtering
 */
const filterSensorTypes: TSensorType[] = [
  SENSOR_TYPE.PM10,
  SENSOR_TYPE.PM2_5,
  SENSOR_TYPE.NO2,
  SENSOR_TYPE.SO2,
  SENSOR_TYPE.O3,
  SENSOR_TYPE.C6H6,
  SENSOR_TYPE.CH2O,
  SENSOR_TYPE.NOISE,
]

/**
 * Tile layer url for color schemes
 */
const tileLayerUrl: Record<string, string> = {
  light: 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png',
  dark: 'https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png',
}

/**
 * Map page
 * @link https://leafletjs.com/SlavaUkraini/reference.html
 * @link https://github.com/Leaflet/Leaflet.markercluster
 */
const MapPage: React.FC<WithTranslation> = ({
  t,
}) => {
  // Used for marekr clusterr and search results
  const showMarkersAtZoom = 13

  // Page visibility
  const isVisible = useIonViewVisibility()

  // Shown at least once, now might be hidden
  // Note: Keeping markers shown adds up +5MB to memore when page is hidden
  const [ isPageConnected, setIsPageConnected ] = useState<boolean>(isVisible)

  const isPointerFine = useMediaQuery('(pointer: fine)')

  useEffect(() => {
    isVisible && setIsPageConnected(true)
  }, [isVisible])

  const ionFabRef = useRef<HTMLIonFabElement>(null)
  const tileLayerRef = useRef<LeafletTileLayer>(null)

  // Global state
  const mapState = useStore(state => state.map)

  // Local state
  const [ map, setMap ] = useState<Map>()

  const [ selectedSensorType, setSelectedSensorType ] = useState<TSensorType>(SENSOR_TYPE.PM10)

  const [ currentStationId, setCurrentStationId ] = useState<number | null>(null)

  const [ isSearbarActive, setIsSearbarActive ] = useState<boolean>(false)

  const [ isMyLocationEnabled, setIsMyLocationEnabled ] = useState<boolean>(false)

  // Stations reading
  const {
    data: stationsReading,
    error,
    isLoading,
    isFallbackData,
    mutate,
  } = useStationsReadingList(isPageConnected, selectedSensorType)

  // Stations list
  // TODO: Filter to max bounds
  const {
    data: stations,
    error: stationsError,
    isLoading: isStationsLoading,
    isFallbackData: isStationsFallbackData,
    mutate: stationsMutate,
  } = useStationsList(isPageConnected)

  // Convert max bounds
  const maxBounds = useMemo(() => new LatLngBounds(MAP_MAX_BOUNDS), [])

  // Narrow to stations used in list
  const filteredStationsReading = useMemo(
    () => filterStationsReadingList(stationsReading, stations, maxBounds),
    [stationsReading, stations, maxBounds]
  )

  // Create tile load event handlers
  const {
    handleTileError,
    handleTileLoad,
    handleTileUnload,
  } = useTileLoadEvents(isVisible)

  // Resolve dark mode
  const isDarkMode = useDarkMode()

  // Close ion-fab when searchbar is in use
  useEffect(() => {
    if (isSearbarActive && ionFabRef.current?.activated) {
      ionFabRef.current?.close()
    }
  }, [isSearbarActive])

  const {
    currentPosition,
    isRequesting: isRequestingPosition,
    error: currentPositionError,
    getPosition: getCurrentPosition,
  } = useCurrentPosition(isMyLocationEnabled)

  // Zoom in to prevent jumps out of bounds and to show all markers
  const moveMapToMyLocation = useCallback((): void => {
    if (map && currentPosition) {
      map.flyTo(
        [ currentPosition.coords.latitude, currentPosition.coords.longitude ],
        Math.max(showMarkersAtZoom, map.getZoom())
      )
    }
  }, [map, currentPosition])

  // Pan to current position after enabling
  useEffect(() => {
    isMyLocationEnabled && currentPosition && moveMapToMyLocation()
  }, [isMyLocationEnabled, currentPosition, moveMapToMyLocation])

  // Update tile layer on color scheme change
  useEffect(() => {
    tileLayerRef.current && tileLayerRef.current.setUrl(isDarkMode ? tileLayerUrl.dark : tileLayerUrl.light)
  }, [isDarkMode])

  /**
   * Handle filter select
   */
  const handleFilterSelect = (sensorType: TSensorType): void =>
    setSelectedSensorType(sensorType)

  /**
   * Handle my location click
   */
  const handleMyLocationClick = (event: React.MouseEvent<HTMLIonFabButtonElement>): void => {
    // Enable, trigger request
    if (!isMyLocationEnabled) {
      setIsMyLocationEnabled(true)
    // Restart position request with default options
    } else if (currentPositionError) {
      getCurrentPosition()
    // Request fresh
    } else if (currentPosition) {
      getCurrentPosition({ maximumAge: Date.now() - currentPosition.timestamp })
    }
  }

  return (
    <Page
      id="map-page"
      title={t('page.Map.title')}
      contentProps={{ className: 'syn-map-page__content', scrollY: false }}
    >
      {/** Filter */}
      <IonFab
        ref={ionFabRef}
        horizontal="end"
        vertical="bottom"
        slot="fixed"
      >
        <IonFabButton>
          <IonIcon icon={chevronUp} />
        </IonFabButton>

        <IonFabList side="top">
          {filterSensorTypes.slice().reverse().map(sensorType =>
            <IonButton
              className="syn-filter-button"
              color={sensorType === selectedSensorType ? 'primary' : 'light'}
              fill="solid"
              shape="round"
              size="small"
              key={sensorType}
              onClick={event => handleFilterSelect(sensorType)}
            >
              {t(`sensorType.${sensorType}.code`)}
            </IonButton>
          )}
        </IonFabList>
      </IonFab>

      {/** My Location */}
      <IonFab
        horizontal="start"
        vertical="bottom"
        slot="fixed"
      >
        <IonFabButton
          color={currentPosition ? 'primary' : 'light' }
          disabled={isRequestingPosition}
          onClick={handleMyLocationClick}
        >
          <IonIcon
            md={locationSharp}
            ios={locationOutline}
          />
        </IonFabButton>
      </IonFab>

      <section className="syn-map">
        {isPageConnected && // Resolve dimensions when page is visible
          <MapContainer
            className="syn-map-container"
            bounds={mapState.bounds}
            maxZoom={18}
            minZoom={5}
            maxBounds={maxBounds}
            tap={false}
            attributionControl={false}
            zoomControl={false}
            whenCreated={setMap}
            preferCanvas={false}
          >
            <TileLayer
              ref={tileLayerRef}
              url={isDarkMode ? tileLayerUrl.dark : tileLayerUrl.light}
              attribution='© <a href="http://osm.org/copyright">OpenStreetMap</a> © <a href="https://carto.com/attributions">CARTO</a>'
              eventHandlers={{
                tileerror: handleTileError,
                tileload: handleTileLoad,
                tileunload: handleTileUnload,
              }}
            />

            <AttributionControl prefix="" />

            <MapEvents setZoomDistantAt={7} />

            {currentPosition &&
              <MyLocationMarker geolocationPosition={currentPosition} />
            }

            <MarkerClusterGroup
              showCoverageOnHover={false}
              animate={true}
              disableClusteringAtZoom={showMarkersAtZoom}
              maxClusterRadius={zoom => Math.sqrt(zoom) * 40}
              spiderfyOnMaxZoom={false}
              iconCreateFunction={markerClusterIcon}
              chunkedLoading={true}
            >
              {filteredStationsReading.map(stationReading =>
                <StationReadingMarker
                  key={stationReading.device.id}
                  stationReading={stationReading}
                  sensorType={selectedSensorType}
                  isPointerFine={isPointerFine}
                  currentStationId={currentStationId}
                  setCurrentStationId={setCurrentStationId}
                />
              )}
            </MarkerClusterGroup>

            <MapSearch
              stations={stations}
              currentStationId={currentStationId}
              setCurrentStationId={setCurrentStationId}
              setIsSearchbarActive={setIsSearbarActive}
              flyToMinZoom={showMarkersAtZoom}
            />
          </MapContainer>
        }
      </section>

      {/** Loading */}
      <LoadingOverlay isOpen={isVisible && (isLoading || isStationsLoading)} />

      {/** Readings error dialog */}
      {isVisible && error && isFallbackData &&
        <TransferErrorAlert
          error={error}
          mutate={mutate}
        />
      }

      {/** Stations error dialog */}
      {isVisible && stationsError && isStationsFallbackData &&
        <TransferErrorAlert
          error={stationsError}
          mutate={stationsMutate}
        />
      }

      {/** Location error */}
      <IonToast
        isOpen={currentPositionError !== undefined}
        color="warning"
        duration={5e3}
        message={t('ui.message.currentPositionError')}
      />
    </Page>
  )
}

export default withTranslation()(MapPage)
