import { SWRConfig } from 'swr'
import type { Cache, SWRConfiguration } from 'swr'

import { useStore } from '../state'
import { createFetcher } from './../utils/fetcher'
import { useCacheProvider } from '../packages/swr-idb-cache'
import { TransferError, ConnectError } from '../utils/Error'

import createRequestMiddleware from '../utils/sentry/request-middleware'

import StorageHandler from './storageHandler'

/** @link https://github.com/vercel/swr/blob/master/src/utils/config-context.ts */
type SWRConfigurationWithProvider = SWRConfiguration & {
  provider?: (cache: Readonly<Cache>) => Cache
}

/**
 * API provider
 */
export const ApiProvider: React.FC<{
  /** API base URL */
  baseUrl: string,
  /** Fallback to render until API cache is ready */
  fallback: JSX.Element,
}> = ({
  children,
  baseUrl,
  fallback,
}) => {
  const favStations = useStore(state => state.favStations)

  const cacheProvider = useCacheProvider({
    dbName: '@syngeos/mobile-app',
    storeName: 'swr-cache',
    // Create storage handler for first access
    storageHandler: new StorageHandler(favStations),
  })

  // Render fallback component
  if (!cacheProvider) {
    return fallback
  }

  // Disable Automatic Revalidation on dev mode (when working in devtools)
  // Note: revalidateIfStale prevents fetch errors
  const isDevEnv = process.env.NODE_ENV === 'development'

  const swrConfig: SWRConfigurationWithProvider = {
    revalidateOnFocus: !isDevEnv,
    revalidateOnReconnect: !isDevEnv,
    shouldRetryOnError: (error: TransferError) =>
      // Retry only on connection errors
      error instanceof ConnectError,
    fetcher: createFetcher(baseUrl),
    provider: cacheProvider,
    use: [
      createRequestMiddleware(baseUrl)
    ],
  }

  return (
    <SWRConfig value={swrConfig}>
      {children}
    </SWRConfig>
  )
}
