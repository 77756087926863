import { useEffect, useMemo } from 'react'
import clsx from 'clsx'
import type { Dispatch, SetStateAction } from 'react'
import type { WithTranslation } from 'react-i18next'
import { withTranslation } from 'react-i18next'
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonButton,
  IonLabel,
  IonProgressBar,
} from '@ionic/react'
import { useSWRConfig } from 'swr'

import { routes, generatePath } from '../../navigation'
import { useStore } from '../../state'
import { remove as removeStation } from '../../state/favstations'
import { deleteCacheEntry } from '../../data/cache'
import type { IStationSensor } from '../../models/Sensor'
import type { TStationMutatorsMap } from '../../pages/Dashboard/DashboardPage.interfaces'
import useStation from '../../data/station'
import { selectUnique, selectStationSensors, selectSelectedStationSensors } from '../../data/selectors/selectStationSensors'
import { isFirstWithNorm } from '../../utils/station-sensor'

import SkeletonText from '../SkeletonText/SkeletonText'
import {
  StationReadingWithNorm,
  StationReadingBasic,
  StationReadingsSkeleton,
} from '../StationReading'

import './DashboardStation.css'

/**
 * Dashboard station
 */
const DashboardStation: React.FC<WithTranslation & {
  id: number,
  isVisible: boolean,
  isNearest?: boolean,
  isReorderLocked?: boolean,
  setMutatorsMap: Dispatch<SetStateAction<TStationMutatorsMap>>,
}> = ({
  id,
  isVisible = true,
  isNearest = false,
  isReorderLocked = false,
  setMutatorsMap,
  t,
}) => {
  // Global state
  const settings = useStore(state => state.settings)
  const dispatch = useStore(state => state.dispatch)

  // Data
  const {
    data: station,
    error,
    endpoint,
    isValidating,
    isFallbackData,
    mutate
  } = useStation(id, isVisible)

  const { cache } = useSWRConfig()

  // Register/ unregister mutator
  useEffect(() => {
    setMutatorsMap(map =>
      map.set(id, mutate)
    )

    return () => setMutatorsMap(map =>
      map.delete(id) ? map : map
    )
  }, [id, mutate, setMutatorsMap])

  // Process sensors
  const stationSensors: IStationSensor[] = useMemo(() =>
    station
      ? selectSelectedStationSensors(selectStationSensors(selectUnique(station.sensors)), settings.sensors)
      : [],
    [station, settings.sensors]
  )

  // Render path
  const stationPath = useMemo(
    () => generatePath(routes.station, { id }),
    [id]
  )

  /**
   * Handle remove action
   */
  const handleRemoveCLick = (): void => {
    // Remove from fav stations
    dispatch(removeStation(id))

    // Remove data from cache and persistent cache
    deleteCacheEntry(cache, endpoint)
  }

  if (error && isFallbackData) {
    return (
      <IonCard
        className={clsx('syn-dashboard-station', isReorderLocked && 'syn-dashboard-station--reorder-locked')}
        href={undefined}
      >
        <IonProgressBar
          type="indeterminate"
          style={{ visibility: isValidating ? 'visible' : 'hidden' }}
        />
        <IonCardHeader>
          <IonLabel color="danger">
            {t(error.tMessageKey)}
          </IonLabel>
        </IonCardHeader>
        <IonButton
          disabled={isValidating}
          fill="clear"
          onClick={() => mutate()}
        >
          {t('ui.button.retry')}
        </IonButton>
        <IonButton
          fill="clear"
          onClick={handleRemoveCLick}
        >
          {t('ui.button.remove')}
        </IonButton>
      </IonCard>
    )
  }

  return (
    <IonCard
      button
      routerLink={stationPath}
      className={clsx('syn-dashboard-station', isReorderLocked && 'syn-dashboard-station--reorder-locked')}
    >
      <IonProgressBar
        type="indeterminate"
        style={{ visibility: isValidating ? 'visible' : 'hidden' }}
      />
      <IonCardHeader>
        {isNearest &&
          <IonCardSubtitle>
            {t('component.DashboardStation.nearest')}
          </IonCardSubtitle>
        }
        <IonCardTitle>
          {station
            ?
              <>
                {station.address &&
                  <span className="syn-station-reading-address">
                    {station.address}
                  </span>
                }
                <span className="syn-station-reading-city">
                  {station.city}
                </span>
              </>
            :
              <SkeletonText width="250px" />
          }
        </IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        {station
          ?
            stationSensors.map(stationSensor =>
              isFirstWithNorm(stationSensor, stationSensors)
                ? <StationReadingWithNorm key={stationSensor.name} stationSensor={stationSensor} type="mono" />
                : <StationReadingBasic key={stationSensor.name} stationSensor={stationSensor} />
            )
          :
            <StationReadingsSkeleton sensors={settings.sensors} />
        }
      </IonCardContent>
    </IonCard>
  )
}

export default withTranslation()(DashboardStation)
