import type { Breadcrumb, BreadcrumbHint } from '@sentry/react'

/**
 * Before breadcumb sent hook
 * @link https://docs.sentry.io/platforms/javascript/enriching-events/breadcrumbs/#customize-breadcrumbs
 * @link https://docs.sentry.io/platforms/javascript/configuration/filtering/
 */
export function beforeBreadcrumb (breadcrumb: Breadcrumb, hint?: BreadcrumbHint): Breadcrumb | null {
  // Filter out ionic errors shown when specific component doesn't render as error boundary takes over
  if (
    breadcrumb.category === 'console' &&
    breadcrumb.message === 'Menu: must have a "content" element to listen for drag events on.'
  ) {
    return null
  }

  // Filter out ionic warnings
  if (
    breadcrumb.category === 'console' &&
    breadcrumb.message === 'split pane does not have a specified main node'
  ) {
    return null
  }

  /**
   * Filter out noisy fetch requests to resources made by ionic components
   * May also use hint or set serverity from info to debug
   * @link https://docs.sentry.io/platforms/javascript/configuration/filtering/#hints-for-breadcrumbs
   */
  if (
    breadcrumb.type === 'http' &&
    breadcrumb.category === 'fetch' &&
    breadcrumb.data!.url.indexOf('data:image/') === 0
  ) {
    return null
  }

  return breadcrumb
}
