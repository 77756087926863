import type { Middleware } from 'swr'
import type { Breadcrumb, SeverityLevel } from '@sentry/types'
import { addBreadcrumb } from '@sentry/browser'
import { ApiError } from '../Error'

/**
 * SWR request logger Middleware
 * @link https://swr.vercel.app/docs/middleware#request-logger
 * @note May not be necessary logs fetch requests properly by default, not sure how about erros
 */
export default function createRequestMiddleware(baseUrl: string): Middleware {
  return swrNext =>
    (key, fetcher, config) => {
      const extendedFetcher = async (path: string, ...restKeyArguments: unknown[]) => {
        const url = `${baseUrl}/${path}`

        // Api/request
        addRequestBreadcrumb('info', url)

        try {
          return await fetcher!(path, ...restKeyArguments)
        } catch (error) {
          // Api/handleError
          addRequestBreadcrumb('warning', url, error as Error)

          throw error
        }
      }

      return swrNext(key, extendedFetcher, config)
    }
}

/**
 * Add sentry breadcrumb
 * @link https://docs.sentry.io/product/issues/issue-details/breadcrumbs/
 */
function addRequestBreadcrumb(
  level: SeverityLevel,
  url: string,
  error?: Error,
): void {
  const breadcrumb: Breadcrumb = {
    type: 'http',
    level,
    category: 'api',
    data: {
      url,
      method: 'GET',
    },
    timestamp: Date.now() / 1e3,
  }

  // Append error message
  if (error) {
    breadcrumb.message = error.message
  }

  // Append response info
  if (error instanceof ApiError) {
    breadcrumb.data!.status_code = error.response.status
    breadcrumb.data!.reason = error.response.statusText
  }

  addBreadcrumb(breadcrumb)
}
