import { Fragment } from 'react'
import type { RouteComponentProps } from 'react-router'
import {
  IonCard,
  IonCardContent,
} from '@ionic/react'
import type { WithTranslation } from 'react-i18next'
import { withTranslation, Trans } from 'react-i18next'

import type { INormGradeKey, INormGrade } from '../../models/Norms'
import type { TGuideSensorType } from '../../models/sensorType'
import useIonViewVisibility from '../../hooks/useIonViewVisibility'
import useNorms from '../../data/norms'
import { hasInfo } from '../../utils/station-sensor'

import Page from '../../components/Page/Page'
import SENSOR_TYPE from '../../constants/sensorType'
import SensorType from '../../components/SensorType/SensorType'
import LoadingOverlay from '../../components/LoadingOverlay/LoadingOverlay'
import NotFoundPage  from '../NotFound/NotFoundPage'

import './GuidePage.css'

interface GuidePageProps extends WithTranslation, RouteComponentProps<{
  sensorType: TGuideSensorType | string
}> {}

/**
 * Guide page
 *
 * @see https://powietrze.gios.gov.pl/pjp/content/health_informations
 * @see https://en.wikipedia.org/wiki/Air_quality_index
 */
const GuidePage: React.FC<GuidePageProps> = ({
  t,
  i18n,
  ...props
}) => {
  const sensorType = props.match.params.sensorType

  // Page visibility
  const isVisible = useIonViewVisibility()

  const { data: norms, isLoading } = useNorms(isVisible)

  // Nothing to show
  if (!hasInfo(i18n, sensorType)) {
    return <NotFoundPage {...props} />
  }

  // Resolve norm grades
  const sensorTypeNorms = norms.find(norm => norm.slug === sensorType)
  const { threshold, ...grades } = sensorTypeNorms?.norm ?? {}

  // Remove empty values
  const normalizedGrades = Object.entries(grades).reduce((acc, [key, grade]) =>
    isNormGrade(grade)
      ? {...acc, [key]: grade}
      : acc,
    {} as Record<INormGradeKey, INormGrade>
  )

  // Prepare for output
  const gradeItems: Array<{
    gradeKey: string,
    label: string,
    desc: string,
    norm: INormGrade,
  }> = (sensorTypeNorms?.slug === SENSOR_TYPE.NOISE)
    ? // Noise special case (6-grade scale -> 3-grade scale)
      [
        {
          gradeKey: 'grade_a-b',
          label: t('grade/noise.grade_a-b.label'),
          desc: t('grade/noise.grade_a-b.desc'),
          norm: {
            gte: normalizedGrades['grade_a'].gte,
            lt: normalizedGrades['grade_b'].lt,
          }
        },
        {
          gradeKey: 'grade_c-d',
          label: t('grade/noise.grade_c-d.label'),
          desc: t('grade/noise.grade_c-d.desc'),
          norm: {
            gte: normalizedGrades['grade_c'].gte,
            lt: normalizedGrades['grade_d'].lt,
          }
        },
        {
          gradeKey: 'grade_e-f',
          label: t('grade/noise.grade_e-f.label'),
          desc: t('grade/noise.grade_e-f.desc'),
          norm: {
            gte: normalizedGrades['grade_e'].gte,
            lt: normalizedGrades['grade_f'].lt,
          }
        }
      ]
    :
      Object.entries(normalizedGrades).map(([gradeKey, normGrade]) => ({
        gradeKey,
        label: t(`grade.${gradeKey as INormGradeKey}.label`),
        desc: t(`grade.${gradeKey as INormGradeKey}.desc`),
        norm: normGrade,
      }))

  return (
    <Page
      id="guide-page"
      title={t('page.Guide.title', { replace: { code: t(`sensorType.${sensorType}.code`) } })}
    >
      <IonCard className="ion-no-margin">
        <IonCardContent>
          <p>
            <strong>
              <Trans
                i18nKey={`sensorType.${sensorType}.name`}
                components={{ SensorType: <SensorType sensorType={sensorType} /> }}
              />
            </strong>
            {' – '}
            <Trans i18nKey={`sensorType.${sensorType}.desc`} />
          </p>

          <dl className="syn-guide-list">
            {gradeItems.map(({ gradeKey, norm, label, desc }) =>
              <Fragment key={gradeKey}>
                <dt className={`syn-guide-list__term syn-guide-term-grade--${gradeKey}`}>
                  {formatNormGrade(norm)}
                </dt>
                <dd className="syn-guide-list__description">
                  <p>
                    <strong>
                      {label}
                    </strong>
                  </p>
                  <p>
                    {desc}
                  </p>
                </dd>
              </Fragment>
            )}

            {/** Unknown */}
            <dt className="syn-guide-list__term syn-guide-term-grade--unknown">
              ?
            </dt>
            <dd className="syn-guide-list__description">
              <p>
                {t('grade.unknown.label')}
              </p>
              <p>
                {t('grade.unknown.desc')}
              </p>
            </dd>
          </dl>
        </IonCardContent>
      </IonCard>

      {/** Loading modal */}
      <LoadingOverlay isOpen={isLoading && isVisible} />
    </Page>
  )
}

/**
 * Format norm grade to string
 */
function formatNormGrade(grade: INormGrade): string | null {
  if (grade.lt) {
    return `${grade.gte} – ${grade.lt}`
  }

  return `${grade.gte} +`
}

/**
 * Check invalid values
 * Note: CAQI doesn't have values last norm (f) but an empty array
 */
function isNormGrade(normGrade: INormGrade | []): normGrade is INormGrade {
  return !Array.isArray(normGrade)
}

export default withTranslation()(GuidePage)
