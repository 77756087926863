import SkeletonText from '../SkeletonText/SkeletonText'

import type { TSensorType } from '../../models/sensorType'
import ColorWheel from '../ColorWheel'

import './StationReading.css'
import './StationReadingWithNorm.css'

/**
 * Station reading skeleton for dashboard
 * Based on
 *  - StationReadingWithNorm
 *  - StationReadingBasic
 * Used when station is added from StationsPage but it's data hasn't been loaded yet
 */
const StationReadingsSkeleton: React.FC<{
  sensors: TSensorType[]
}> = ({
  sensors,
}) =>
  <>
    {/** Station reading with norm */}
    <div className="syn-dashboard-reading syn-dashboard-reading--type-mono">
      <div className="syn-dashboard-reading__description">
        <p className="syn-station-reading-norm-label">
          <SkeletonText width="200px" />
        </p>
        <p className="syn-station-reading-description">
          <SkeletonText width="200px" />
        </p>
        <p className="syn-station-reading-date">
          <SkeletonText width="200px" />
        </p>
      </div>

      <div className="syn-dashboard-reading__data">
        <div className="syn-station-reading-norm syn-station-reading-norm--type-mono">
          <div className="syn-station-reading-norm__data">
            <span className="syn-station-reading-norm__value">
              <SkeletonText width="50px" />
            </span>
            <span className="syn-station-reading-norm__unit">
              <SkeletonText width="50px" />
            </span>
            <span className="syn-station-reading-norm__label">
              <SkeletonText width="50px" />
            </span>
          </div>

          <ColorWheel
            type="mono"
            stationSensor={undefined}
          />
        </div>
      </div>
    </div>
    {/** Station reading basic */}
    {sensors.slice(1).map(sensorType =>
      <div
        key={sensorType}
        className="syn-dashboard-reading"
      >
        {/** Meta */}
        <div className="syn-dashboard-reading__description">
          <div className="syn-station-reading-sensor-type">
            <SkeletonText width="200px" />
          </div>
        </div>
        {/** Data */}
        <div className="syn-dashboard-reading__data syn-station-reading-basic">
          {/** Value + Unit */}
          <span className="syn-station-reading-basic__value">
            <SkeletonText width="5em" />
          </span>
        </div>
      </div>
    )}
  </>

export default StationReadingsSkeleton
