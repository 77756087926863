import NumberEasing from 'react-number-easing'
import type { WithTranslation } from 'react-i18next'
import { withTranslation  } from 'react-i18next'
import { IonIcon } from '@ionic/react'
import {
  helpCircleSharp,
  helpCircleOutline,
} from 'ionicons/icons'

import type { IStationSensorWithNorm } from '../../models/Sensor'

import { getGradeKey } from '../../utils/format/grade'

import NormGradeLabel from '../NormGradeLabel/NormGradeLabel'
import ColorWheel from '../ColorWheel'
import SensorType from '../SensorType/SensorType'
import ReadingDate from '../ReadingDate/ReadingDate'

import './StationReading.css'
import './StationReadingWithNorm.css'

/**
 * Reading for sensor with norms including effects (conic-gradient)
 * Note: background-color: conic-gradient is not supported in older browsers
 * @link https://stackoverflow.com/questions/18206361/svg-multiple-color-on-circle-stroke
 * @link https://wiki.inkscape.org/wiki/Advanced_Gradients
 * Adapted from {@link https://codepen.io/philipnrmn/pen/mOVMOd}
 * With following modifications:
 * - Added angle to gradients
 * - Extended radius
 */
const StationReadingWithNorm: React.FC<WithTranslation & {
  stationSensor: IStationSensorWithNorm,
  type: '6-grade' | 'mono',
  showHint?: boolean,
}> = ({
  stationSensor,
  type,
  showHint = false,
  t,
}) =>
  <div className={`syn-dashboard-reading syn-dashboard-reading--type-${type}`}>
    {/** Meta */}
    <div className="syn-dashboard-reading__description">
      <p className="syn-station-reading-norm-label">
        <NormGradeLabel gradeKey={getGradeKey(stationSensor)} />
      </p>
      <p className="syn-station-reading-description">
        {t('component.StationReadingWithNorm.airQuality')}
      </p>
      <p className="syn-station-reading-date">
        {stationSensor.data.length
          ? <ReadingDate dateIso8601={stationSensor.data[0].read_at} type="datetime" />
          : '—'
        }
      </p>
    </div>
    {/** Data */}
    <div className="syn-dashboard-reading__data">
      <div className={`syn-station-reading-norm syn-station-reading-norm--sensor-${stationSensor.name} syn-station-reading-norm--type-${type}`}>
        <div className="syn-station-reading-norm__data">
          <span className="syn-station-reading-norm__value">
            {stationSensor.data.length
              ?
                <NumberEasing
                  value={stationSensor.data[0].value}
                  speed={250}
                  decimals={0}
                  ease="sineInOut"
                />
              : '—'
            }
          </span>
          <span className="syn-station-reading-norm__unit">
            {stationSensor.unit}
          </span>
          <span className="syn-station-reading-norm__label">
            <SensorType sensorType={stationSensor.name} />
          </span>
        </div>

        <ColorWheel
          type={type}
          stationSensor={stationSensor}
        />

        {/** Guide/ legend hint */}
        {showHint && type === '6-grade' &&
          <IonIcon
            className="syn-station-reading__hint"
            color="secondary"
            md={helpCircleSharp}
            ios={helpCircleOutline}
          />
        }
      </div>
    </div>
  </div>

export default withTranslation()(StationReadingWithNorm)
