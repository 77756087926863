import type { WithTranslation } from 'react-i18next'
import { withTranslation } from 'react-i18next'

import type { INormGradeKey } from '../../models/Norms'

/**
 * Norm grade label
 */
const NormGradeLabel: React.FC<WithTranslation & {
  gradeKey: INormGradeKey | null,
}> = ({
  gradeKey,
  t,
}) =>
  <>
    {t(`grade.${gradeKey ?? 'unknown'}.label`)}
  </>

export default withTranslation()(NormGradeLabel)
