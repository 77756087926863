import { memo } from 'react'
import type {
  LeafletMouseEvent,
  LeafletKeyboardEvent,
} from 'leaflet'
import {
  Marker as LeafletMarker,
  Tooltip,
  useMap,
} from 'react-leaflet'
import { useHistory } from 'react-router-dom'

import type { TSensorType } from '../../models/sensorType'
import type { IStationsReadingItem } from '../../models/Station'
import { routes, generatePath } from '../../navigation'
import { stationReadingIcon } from '../MarkerIcon/StationReadingIcon'

/**
 * Marker
 */
const StationReadingMarker: React.FC<{
  stationReading: IStationsReadingItem,
  sensorType: TSensorType,
  isPointerFine: boolean,
  currentStationId: number | null,
  setCurrentStationId: React.Dispatch<React.SetStateAction<number | null>>,
}> = ({
  stationReading,
  sensorType,
  isPointerFine,
  currentStationId,
  setCurrentStationId,
}) => {
  const map = useMap()
  const history = useHistory()

  /**
   * Handle click
   * TODO: Show sheet modal (ionic v6) instead of navigating or floating card
   * Problem: Modals created with modalController are out of context
   */
  const handleMarkerClick = (leafletMouseEvent?: LeafletMouseEvent): void => {
    map.addOneTimeEventListener('moveend', () =>
      history.push(generatePath(routes.station, { id: stationReading.device.id }))
    )

    // Pan map to marker. Default duration is .25s
    map.flyTo(stationReading.device.coordinates)

    setCurrentStationId(stationReading.device.id)
  }

  /**
   * Handle key press, proxy to click handler
   */
  const handleMarkerKeypress = (leafletKeyboardEvent: LeafletKeyboardEvent): void => {
    const event = leafletKeyboardEvent.originalEvent

    if (
      event.key === 'Enter' &&
      !event.altKey &&
      !event.ctrlKey &&
      !event.metaKey &&
      !event.shiftKey
    ) {
      handleMarkerClick()
    }
  }

  return (
    <LeafletMarker
      position={stationReading.device.coordinates}
      icon={stationReadingIcon(
        stationReading,
        sensorType,
        stationReading.device.id === currentStationId,
      )}
      eventHandlers={{
        click: handleMarkerClick,
        keypress: handleMarkerKeypress,
      }}
      pane="markerPane"
    >
      {isPointerFine &&
        <Tooltip pane="tooltipPane">
          {[stationReading.device.city, stationReading.device.address].join(', ')}
        </Tooltip>
      }
    </LeafletMarker>
  )
}

export default memo(StationReadingMarker, (prevProps, nextProps) => (
  prevProps.sensorType === nextProps.sensorType &&
  prevProps.currentStationId === nextProps.currentStationId &&
  prevProps.stationReading.device.id === nextProps.stationReading.device.id &&
  prevProps.stationReading.data.current_norm === nextProps.stationReading.data.current_norm
))
