import { useState, useEffect, useMemo } from 'react'
import type { RouteComponentProps } from 'react-router'
import { Link } from 'react-router-dom'
import type { WithTranslation } from 'react-i18next'
import { withTranslation } from 'react-i18next'
import {
  IonButton,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonRefresher,
  IonRefresherContent,
} from '@ionic/react'
import type { SegmentChangeEventDetail, RefresherEventDetail } from '@ionic/core'
import {
  addSharp, addOutline,
  trashSharp, trashOutline
} from 'ionicons/icons'
import { useSWRConfig } from 'swr'

import { routes, generatePath } from '../../navigation'
import useIonViewVisibility from '../../hooks/useIonViewVisibility'
import useStation from '../../data/station'
import useStationHistorical from '../../data/stationHistorical'
import { useStore } from '../../state'
import useDarkMode from '../../hooks/useDarkMode'
import {
  add as addStation,
  remove as removeStation,
} from '../../state/favstations'
import { selectStationSensors, selectUnique } from '../../data/selectors/selectStationSensors'
import { deleteCacheEntry } from '../../data/cache'
import type { IStationSensor, IStationSensorBasic, IStationSensorWithNorm } from '../../models/Sensor'
import { isWithNorm, hasInfo, isWeatherType } from '../../utils/station-sensor'

import Page from '../../components/Page/Page'
import { StationReadingWithNorm } from '../../components/StationReading'
import TransferErrorAlert from '../../components/TransferErrorAlert/TransferErrorAlert'
import SensorType from '../../components/SensorType/SensorType'
import Chart from '../../components/Chart/Chart'
import WeatherItem from '../../components/WeatherItem/WeatherItem'
import LoadingOverlay from '../../components/LoadingOverlay/LoadingOverlay'

import backgroundImageLightSrc from './images/station-light.svg'
import backgroundImageDarkSrc from './images/station-dark.svg'
import './StationPage.css'

interface StationPageProps extends WithTranslation, RouteComponentProps<{
  id: string,
}> {}

// @link https://ionicframework.com/docs/api/segment#segmentcustomevent
interface SegmentChangeEvent extends CustomEvent {
  detail: SegmentChangeEventDetail,
}

/**
 * Station page
 */
const StationPage: React.FC<StationPageProps> = ({
  match,
  history,
  t,
  i18n,
}) => {
  // Resolve id
  const stationId = Number.parseInt(match.params.id)

  // Page visibility
  const isVisible = useIonViewVisibility()

  // Data
  const {
    data: station,
    error: stationError,
    endpoint: stationEndpoint,
    isLoading: isStationLoading,
    isFallbackData: isStationFallbackData,
    mutate: stationMutate,
  } = useStation(stationId, isVisible)

  const {
    data: stationHistoricalData,
    endpoint: stationHistoricalEndpoint,
    isLoading: isStationHistoricalLoading,
    mutate: stationHistoricalMutate,
  } = useStationHistorical(stationId, isVisible)

  // Global state
  const favStations = useStore(state => state.favStations)
  const dispatch = useStore(state => state.dispatch)

  const { cache } = useSWRConfig()

  // Process sensors
  const stationSensors: IStationSensor[] = useMemo(() =>
    station
      ? selectStationSensors(selectUnique(station.sensors))
      : [],
    [station]
  )

  // Sensors with norms
  const stationSensorsWithNorms: IStationSensorWithNorm[] = useMemo(() =>
    stationSensors.filter((stationSensor): stationSensor is IStationSensorWithNorm => isWithNorm(stationSensor)),
    [stationSensors]
  )

  // TODO: Whitelist (exclude pm1)
  const stationSensorsWeather: IStationSensorBasic[] = useMemo(() =>
    stationSensors.filter((stationSensor): stationSensor is IStationSensorBasic => isWeatherType(stationSensor)),
    [stationSensors]
  )

  const [ selectedSensor, setSelectedSensor ] = useState<IStationSensorWithNorm>()

  // Resolve dark mode
  const isDarkMode = useDarkMode()

  useEffect(() => {
    stationSensorsWithNorms.length && setSelectedSensor(stationSensorsWithNorms[0])
  }, [stationSensorsWithNorms])

  // Handle historical data
  const historicalData = (stationHistoricalData && selectedSensor)
    ? stationHistoricalData.sensors.find(sensorsData => sensorsData.name === selectedSensor.name) as IStationSensorWithNorm
    : undefined

  /**
   * Handle refresh action
   */
  const refresh = (event: CustomEvent<RefresherEventDetail>): void => {
    Promise.all([
      stationMutate,
      stationHistoricalMutate,
    ])
      .finally(() => event.detail.complete())
  }

  /**
   * Handle add action
   */
  const handleAddClick = () => {
    dispatch(addStation(stationId))
  }

  /**
   * Handle remove action
   */
  const handleRemoveClick = (): void => {
    // Remove from fav stations
    dispatch(removeStation(stationId))

    // Remove data from cache and persistent cache
    Promise.all([
      deleteCacheEntry(cache, stationEndpoint),
      deleteCacheEntry(cache, stationHistoricalEndpoint)
    ]).then(() =>
      // Redirect
      history.push(routes.dashboard)
    )
  }

  /**
   * Handle selected sensor change
   */
  const handleSelectedSensorChange = (event: SegmentChangeEvent): void =>
    setSelectedSensor(
      stationSensorsWithNorms.find(
        stationSensor => stationSensor.name === event.detail.value
      )
    )

  return (
    <Page
      id="station-page"
      title={t('page.Station.title')}
      toolbarPrimaryButton={!favStations.includes(stationId)
        ?
          <IonButton onClick={handleAddClick}>
            <IonIcon
              slot="icon-only"
              md={addSharp}
              ios={addOutline}
            />
          </IonButton>
        :
          <IonButton onClick={handleRemoveClick}>
            <IonIcon
              slot="icon-only"
              md={trashSharp}
              ios={trashOutline}
            />
          </IonButton>
      }
    >
      <IonRefresher slot="fixed" onIonRefresh={refresh}>
        <IonRefresherContent />
      </IonRefresher>

      <img
        className="syn-bgimage syn-bgimage--station"
        src={isDarkMode ? backgroundImageDarkSrc : backgroundImageLightSrc}
        width="245"
        height="70"
        alt=""
      />

      {station &&
        <IonCard className="ion-no-margin">
          <IonCardHeader>
            <div className="syn-card-section-heading">
              {t('page.Station.last24')}
            </div>
            <IonCardTitle>
              {station.address &&
                <span className="syn-station-reading-address">
                  {station.address}
                </span>
              }
              <span className="syn-station-reading-city">
                {station.city}
              </span>
            </IonCardTitle>
          </IonCardHeader>

          <IonCardContent>
            {/** Ostatnie 24h */}
            {selectedSensor &&
              (hasInfo(i18n, selectedSensor.name)
                ?
                  <Link
                    className="syn-link"
                    to={generatePath(routes.guide, { sensorType: selectedSensor.name })}
                  >
                    <StationReadingWithNorm stationSensor={selectedSensor} type="6-grade" showHint />
                  </Link>
                :
                  <StationReadingWithNorm stationSensor={selectedSensor} type="6-grade" />
              )
            }

            {/** Sensors with norms */}
            <div className="syn-card-section-heading">
              {t('page.Station.readings')}
            </div>
            <IonSegment
              className="syn-select-stations"
              scrollable
              value={selectedSensor?.name}
              onIonChange={handleSelectedSensorChange}
            >
              {stationSensorsWithNorms.map(stationSensor =>
                <IonSegmentButton
                  key={stationSensor.name}
                  className="syn-select-station"
                  layout="icon-hide"
                  value={stationSensor.name}
                >
                  <div className="syn-select-station__value-unit">
                    <strong className="syn-select-station__value">
                      {stationSensor.data.length
                        ? stationSensor.data[0].value.toFixed(0)
                        : '—'
                      }
                    </strong>
                    <span className="syn-select-station__unit">
                      {stationSensor.unit}
                    </span>
                  </div>
                  <IonLabel className="syn-select-station__type">
                    <SensorType sensorType={stationSensor.name} />
                  </IonLabel>
                </IonSegmentButton>
              )}
            </IonSegment>

            {/** Chart */}
            <Chart
              historicalData={historicalData}
              isLoading={isStationHistoricalLoading}
            />

            {/** Wather sensors */}
            {stationSensorsWeather.length !== 0 &&
              <>
                <div className="syn-card-section-heading">
                  {t('page.Station.weather')}
                </div>
                <IonGrid>
                  <IonRow>
                    {stationSensorsWeather.map(stationSensor =>
                      <IonCol
                        key={stationSensor.name}
                        className="ion-text-center"
                      >
                        <WeatherItem stationSensor={stationSensor} />
                      </IonCol>
                    )}
                  </IonRow>
                </IonGrid>
              </>
            }

            {/** Source */}
            <IonLabel
              color="primary"
              className="syn-station-source ion-margin-top"
            >
              {t('page.Station.source', { replace: { source: t(`page.Station.sourceLabel.${station.source}`) } } )}
            </IonLabel>

          </IonCardContent>
        </IonCard>
      }

      {/** Loading */}
      <LoadingOverlay isOpen={isStationLoading && isVisible} />

      {/** Error dialog */}
      {stationError && isStationFallbackData && isVisible &&
        <TransferErrorAlert
          error={stationError}
          mutate={stationMutate}
        />
      }
    </Page>
  )
}

export default withTranslation()(StationPage)
