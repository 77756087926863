import type { IMap } from './map.types'
import { MAP_MAX_BOUNDS } from '../../constants/map'

/**
 * Poland
 * Latitude:  54°50' N .. 49°00' S
 * Longitude: 24°09' E .. 14°07' W
 * Center: 52.0152, 19.13
 * @see https://worldpopulationreview.com/countries/poland/location
 */
const initialState: IMap = {
  bounds: MAP_MAX_BOUNDS,
}

export default initialState
