import type { LatLngBoundsLiteral } from 'leaflet'

/**
 * Poland
 * Latitude:  54°50' N .. 49°00' S
 * Longitude: 24°09' E .. 14°07' W
 * Center: 52.0152, 19.13
 * @see https://worldpopulationreview.com/countries/poland/location
 */
const MAP_MAX_BOUNDS: LatLngBoundsLiteral = [
  [54.84, 24.15],
  [49.00, 14.11],
]

export { MAP_MAX_BOUNDS }
