import { useState, useEffect } from 'react'
import clsx from 'clsx'
import type { WithTranslation } from 'react-i18next'
import { withTranslation } from 'react-i18next'
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonRefresher,
  IonRefresherContent,
  IonReorder,
  IonReorderGroup,
  IonFooter,
  IonToolbar,
} from '@ionic/react'
import type { RefresherEventDetail, ItemReorderEventDetail } from '@ionic/core'
import {
  add,
  addSharp,
  addOutline,
  pencilSharp,
  pencilOutline,
  checkmarkSharp,
  checkmarkOutline,
} from 'ionicons/icons'

import routes from '../../navigation/routes'
import useIonViewVisibility from '../../hooks/useIonViewVisibility'
import { useStore } from '../../state'
import useStationsList from '../../data/stationsList'
import type { IStationListItem } from '../../models/Station'
import type { TStationMutatorsMap } from './DashboardPage.interfaces'
import { useCurrentPosition } from '../../hooks/useGeolocation'
import useDarkMode from '../../hooks/useDarkMode'
import { selectNearestStation } from '../../data/selectors/selectStations'
import { reorder } from '../../state/favstations'

import Page from '../../components/Page/Page'
import DashboardStation from '../../components/DashboardStation/DashboardStation'

import backgroundImageLightSrc from './images/dashboard-light.svg'
import backgroundImageDarkSrc from './images/dashboard-dark.svg'
import './DashboardPage.css'

/**
 * Dashboard page
 */
const DashboardPage: React.FC<WithTranslation> = ({
  t,
}) => {
  // Page visibility
  const isVisible = useIonViewVisibility()

  // Global state
  const favStations  = useStore(state => state.favStations)
  const settings = useStore(state => state.settings)
  const dispatch = useStore(state => state.dispatch)

  // Revalidation
  const [ mutatorsMap, setMutatorsMap ] = useState<TStationMutatorsMap>(new Map())

  const {
    currentPosition,
    error: currentPositionError,
  } = useCurrentPosition(settings.closestDevice)

  const [ nearestStation, setNearestStation ] = useState<IStationListItem|null>()

  // Load stations only when nearest station position is enabled as only when it's being used
  const { data: stations } = useStationsList(isVisible && currentPosition !== undefined)

  const [ isReordering, setIsReordering ] = useState<boolean>(false)

  // Resolve dark mode
  const isDarkMode = useDarkMode()

  // Update nearest station
  // Note: Nearest station doesn't show when stations list is empty without indication to user
  useEffect(() => {
    // Remove nearest station
    if (!settings.closestDevice) {
      setNearestStation(null)
      return
    }

    // Not enough data, wait for next hook invocation
    if (!currentPosition) {
      return
    }

    const station = selectNearestStation(stations, [
      currentPosition.coords.latitude,
      currentPosition.coords.longitude,
    ])

    setNearestStation(station)
  }, [currentPosition, stations, settings.closestDevice])

  // Disable reordering when leaving screen
  useEffect(() => {
    if (!isVisible && isReordering) {
      setIsReordering(false)
    }

  }, [isVisible, isReordering])

  // Trigger revalidation
  const refresh = (event: CustomEvent<RefresherEventDetail>): void => {
    const mutators = Array.from(mutatorsMap.values())

    Promise.all(mutators.map(mutator => mutator()))
      .finally(() => event.detail.complete())
  }

  // Reorder button click
  const handleReorderToggle = (): void => {
    setIsReordering(!isReordering)
  }

  /**
   * Handle reorder
   * @link https://ionicframework.com/docs/v6/api/reorder#updating-data
   */
  const handleReorder = (event: CustomEvent<ItemReorderEventDetail>): void =>
    void dispatch(reorder(event.detail.complete(favStations)))

  return (
    <Page
      id="home-page"
      dashboard
      toolbarPrimaryButton={
        <IonButton routerLink={routes.stations}>
          <IonIcon
            slot="icon-only"
            md={addSharp}
            ios={addOutline}
          />
        </IonButton>
      }
      toolbarStartButton={
        <IonButton
          disabled={favStations.length === 0}
          onClick={handleReorderToggle}
        >
          <IonIcon
            slot="icon-only"
            md={isReordering ? checkmarkSharp : pencilSharp}
            ios={isReordering ? checkmarkOutline : pencilOutline}
          />
        </IonButton>
      }
    >
      {!isReordering && // Prevent [Intervention] error message
        <IonRefresher slot="fixed" onIonRefresh={refresh}>
          <IonRefresherContent />
        </IonRefresher>
      }

      <IonFab
        horizontal="center"
        vertical="bottom"
        slot="fixed"
      >
        <IonFabButton routerLink={routes.stations}>
          <IonIcon icon={add} />
        </IonFabButton>
      </IonFab>

      <div className={clsx('syn-dashboard-content', isReordering && 'syn-dashboard-content--reordering')}>
        {/** Position error */}
        {settings.closestDevice && !nearestStation && currentPositionError &&
          <IonLabel
            className="ion-padding"
            color="danger"
          >
            {t('ui.message.currentPositionError')}
          </IonLabel>
        }

        {/** Nearest station, when not already included in favs */}
        {nearestStation && !favStations.includes(nearestStation.id) &&
          <DashboardStation
            id={nearestStation.id}
            isNearest
            isVisible={isVisible}
            isReorderLocked
            setMutatorsMap={setMutatorsMap}
          />
        }

        {/** Fav stations */}
        {favStations.length !== 0
          ? // Stations list
            <IonReorderGroup
              disabled={!isReordering}
              onIonItemReorder={handleReorder}
            >
              {favStations.map(id =>
                <IonReorder key={id}>
                  <DashboardStation
                    id={id}
                    isNearest={nearestStation?.id === id}
                    isVisible={isVisible}
                    setMutatorsMap={setMutatorsMap}
                  />
                </IonReorder>
              )}
            </IonReorderGroup>
          : !nearestStation && // Placeholder
            <IonCard>
              <IonCardContent>
                <IonItem
                  button={false}
                  detail={false}
                  lines="none"
                  routerLink={routes.stations}
                >
                  <IonLabel>
                    {t('page.Dasboard.noStations')}
                  </IonLabel>
                </IonItem>
              </IonCardContent>
            </IonCard>
        }

        {/** Background image */}
        <img
          className="syn-bgimage syn-bgimage--dashboard"
          src={isDarkMode ? backgroundImageDarkSrc : backgroundImageLightSrc}
          width="230"
          height="160"
          alt=""
        />

        {/** Footer */}
        <IonFooter className="syn-dashboard-footer ion-no-border">
          <IonToolbar>
            <a
              slot="start"
              className="ion-margin-horizontal"
              href="https://syngeos.pl/o-nas/polityka-prywatnosci/"
              target="external"
            >
              {t('page.Dasboard.privacyPolicy')}
            </a>
            <a
              slot="end"
              className="ion-margin-horizontal"
              href="https://syngeos.pl/"
              target="external"
            >
              {'© Syngeos'}
            </a>
          </IonToolbar>
        </IonFooter>
      </div>
    </Page>
  )
}

export default withTranslation()(DashboardPage)
